import axios from "axios";

export const postItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      // 'Access-Control-Allow-Origin': '*'
    }
  }
  // Actual call
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const postFileItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      // "Content-type": "application/json",
      "Content-Type": "multipart/form-data",
      'Access-Control-Allow-Origin': '*'
    }
  }
  // Actual call
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const getItem = (path, token) => {
  let config = {
    timeout: 17000,
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }
  // Actual call
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)

}

export const getFileItem = (path, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    },
    responseType: 'blob'
  }
  // Actual call
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)

}

export const requestFileItem = (url, token) => {
  var config
  if (token === undefined) {
    config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      responseType: 'blob'
    }
  } else {
    config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      },
      responseType: 'blob'
    }
  }
  // Actual call
  return axios.request(url, config)

}


export const updateItem = (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  // Actual call
  return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const updateFileItem = async (path, file, token) => {
  const formData = new FormData();
  formData.append('file', file);
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': file.type
    }
  }
  // Actual call
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, formData, config)

}


export const deleteItem = (path, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }
  // Actual call
  return axios.delete(`${process.env.REACT_APP_API_BASE}${path}`, config)

}
