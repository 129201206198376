import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import { ThreeCircles } from 'react-loader-spinner';
import { getItem, postItem } from "util/api.svs";
import { TextField, Checkbox, FormControlLabel, Autocomplete, IconButton } from '@mui/material';
import MDInput from "components/MDInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

function Merit() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(ApiContext);
  const { isLoading: contextIsLoading, schoolData } = context;

  const [dataPoints, setPoints] = useState(null);
  const [achievement, setAchievement] = useState("None");

  const navigate = useNavigate();

  const handleSubmit = () => {
    // Assuming datapoints is available in your scope
    const { student_id } = dataPoints;
    // Route to the page with studentid
    navigate(`/student/${student_id}`);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        getItem(`/users/merit/${id}`, token).then(resp => {
          console.log("Merit!!", resp.data)
          setPoints(resp.data)
          setIsLoading(false)
        }).catch(error => {
          Sentry.captureException(error)
          setIsLoading(false)
        })
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
    if (!contextIsLoading) {
      fetchData();
    }
  }, [contextIsLoading]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          {isLoading ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              />
            </div>
          ) : (
            <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Grid item xs={12} sx={{ position: "absolute", left: 10, top: 8, bottom: 0 }}>
                {/* Back Arrow Link */}
                <IconButton sx={{ marginRight: '10px', fontSize: "2rem" }} component={Link} to="/merits">
                  <ArrowBackIcon />
                </IconButton>
              </Grid>

              <MDTypography variant="h6" mb={3} sx={{ color: "#F7941D", fontSize: "1.5rem", fontWeight: 500}}>
                Merit Details
              </MDTypography>
              
              {dataPoints && ( // Render only if student data is available
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDTypography variant="body1">Student: <span className="special-text">{dataPoints.student_display_name}</span></MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body1">Reason: <span className="special-text">{dataPoints.reason}</span></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Status: <span className={dataPoints.is_status === "approved" ? "special-text green-text" : "special-text"}>{dataPoints.is_status}</span></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Approved: <span className="special-text">{dataPoints.approved_by}</span></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Submitted: <span className="special-text">{dataPoints.submitted_by}</span></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Point Total: <span className="special-text">{dataPoints.total}</span></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Point Total: <span className="special-text">{dataPoints.date_given}</span> </MDTypography>
                  </Grid>

                  <Grid item xs={12}>
                    <MDTypography variant="body1">Point Notes: <span className="special-text">{dataPoints.notes}</span></MDTypography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MDButton
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: "#F7941D", color: "#fff", fontFamily: "Poppins",
                        "&: hover": {
                          backgroundColor: "#E2E8F0",
                          color: "#081c3d",
                          border: "1px solid #081c3d"
                        }
                      }}
                    >
                      View Student
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </MDBox>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Merit;
