import React from "react";
// import '../pages.css';
import "assets/styles/tailwind.css";
// import './index.css';

export default function PublicFooter() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold meritums-text-blue footer-text">Let's keep in touch!</h4>
              <h5 className="text-lg mt-0 mb-2 meritums-text-grey">
                Find us on any of these platforms.
              </h5>

              <div className="mt-6 lg:mb-0 mb-6">
                <a href="mailto:info@gistics.co.za">
                  <svg class="h-8 w-8 meritums-text-yellow hover:text-meritums-blue"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"/>
                  </svg>
                </a>

                {/* <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-github"></i>
                </button> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase meritums-text-blue text-base font-bold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="meritums-text-grey hover:text-meritums-cyan font-semibold block pb-2 text-sm footer-text"
                        href="/contact"
                      >
                        Contact Us
                      </a>
                    </li>
                    
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase meritums-text-blue text-base font-bold mb-2">
                    Other Resources
                  </span>

                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="meritums-text-grey hover:text-meritums-cyan font-semibold block pb-2 text-sm footer-text"
                        href="https://app.enzuzo.com/policies/tos/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEyOCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1UdUdyOFBkRyIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODg4NDAxOTV9.UpWt8hBCmq4PJhVu4qFjvgw_iyqxxt9RB8Wpp4NUdgY"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </li>

                    <li>
                      <a
                        className="meritums-text-grey hover:text-meritums-cyan font-semibold block pb-2 text-sm footer-text"
                        target="_blank"
                        href="https://app.enzuzo.com/policies/privacy/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEyOCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1UdUdyOFBkRyIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODg4MzU4MTl9.zyqdbQG1O62EQeE9XJZjbJICuAF52_SUHVwTQ-g1z28"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        className="meritums-text-grey hover:text-meritums-cyan font-semibold block pb-2 text-sm footer-text"
                        href="/contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm meritums-text-cyan font-semibold py-1 footer-text">
                Copyright ©2024 <a className="pyrrho-link" target="_" href="https://www.pyrrhotech.com/">Pyrrho Tech</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
