import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import { ThreeCircles } from 'react-loader-spinner';
import { getItem, postItem } from "util/api.svs";
import { TextField, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import MDInput from "components/MDInput";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Delete as DeleteIcon, Add as AddIcon, ArrowUpward as ArrowUpwardIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

function Student() {

  const navigate = useNavigate();

  const { student_id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(ApiContext);
  const { isLoading: contextIsLoading, schoolData } = context;

  const [rows, setRow] = useState([]);
  const [dataPoints, setPoints] = useState(null);
  const [student, setStudent] = useState(null); // Changed initial state to null
  const [achievement, setAchievement] = useState("None");
  const [achievementColor, setAchievementColor] = useState(schoolData.color);


  const viewMerit = (merit) => {
    navigate(`/merit/${merit.id}`);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        getItem(`/admin/schools/${schoolData.uuid}/points`, token).then(pointResponse => {
          console.log("Points!!", pointResponse.data)
          setPoints(pointResponse.data)
          getItem(`/admin/students/${student_id}`, token).then(resp => {
            console.log("Student!!", resp.data)
            setStudent(resp.data)
            const sortedDataPoints = pointResponse.data.sort((a, b) => a.min_point - b.min_point);

            if (sortedDataPoints && resp.data.point_total) {
              let achievementFound = false; // Flag to check if achievement is found
              for (let i = 0; i < pointResponse.data.length; i++) {
                console.log("Checking")
                if (resp.data.point_total >= pointResponse.data[i].min_point) {
                  console.log(pointResponse.data[i])
                  setAchievement(pointResponse.data[i].name);
                  setAchievementColor(pointResponse.data[i].color || schoolData.color)
                  achievementFound = true;
                }
              }
              if (!achievementFound) {
                // If no achievement found, set it to 'None'
                setAchievement("None");
              }
            }
            console.log("Passing achievement")
            getItem(`/users/merit-points/${student_id}/student`, token).then(resp => {
              console.log(resp.data)
              setRow(resp.data)
              setIsLoading(false)
            }).catch(error => {
              console.log(error)
              Sentry.captureException(error)
              setIsLoading(false)
            })

          }).catch(error => {
            console.log(error)
            Sentry.captureException(error)
            setIsLoading(false)
          })
        }).catch(error => {
          Sentry.captureException(error)
          setIsLoading(false)
        })
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
    if (!contextIsLoading) {
      fetchData();
    }
  }, [contextIsLoading]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          {isLoading ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              />
            </div>
          ) : (
            <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Grid item xs={12} sx={{ position: "absolute", left: 10, top: 8, bottom: 0 }}>
                {/* Back Arrow Link */}
                <IconButton sx={{ marginRight: '10px', fontSize: "2rem" }} component={Link} to="/students">
                  <ArrowBackIcon />
                </IconButton>
              </Grid>

              <MDTypography variant="h6" mb={3} sx={{ color: "#F7941D", fontSize: "1.5rem", fontWeight: 500 }}>
                Student Details
              </MDTypography>

              {student && ( // Render only if student data is available
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDTypography variant="body1" sx={{ fontSize: "1.4rem" }}>
                      Achievement: <span style={{ color: achievementColor }}>{achievement}</span>
                    </MDTypography>
                  </Grid>
                  {!student.badge_awarded && (
                  <Grid item xs={12}>
                    <MDTypography variant="body1" sx={{ fontSize: "1.4rem" }}>
                      This student should receive a new badge. The last badge was received at : <span>{student.last_badge_awarded_total}</span> points
                    </MDTypography>
                  </Grid>
                  )}
                  <Grid item xs={6}>
                    <MDTypography variant="body1">First Name: <em><strong>{student.first_name}</strong></em></MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body1">Last Name: <em><strong>{student.last_name}</strong></em></MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body1">Gender: <em><strong>{student.gender}</strong></em></MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body1">Grade: <em><strong>{student.grade}</strong></em></MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">
                      Status: <span style={{ color: student.is_status === 'active' ? 'green' : 'red' }}>{student.is_status}</span>
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body1">Point Total:  <em><strong>{student.point_total}</strong></em> </MDTypography>
                  </Grid>
                  {rows == null || rows.length === 0 ? null : (
                  <Grid item xs={12}>
                    <DataGrid
                      sx={{
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                          color: '#081c3d',
                          fontWeight: 700,
                        },
                      }}
                      rows={rows}
                      columns={[
                        { field: 'student_display_name', headerName: 'Student', flex: 1 },
                        {
                          field: 'Point', headerName: 'Point', flex: 1, renderCell: (params) => {
                            return params.row.total.toString()
                          }
                        },
                        { field: 'reason', headerName: 'Reason', flex: 1 },
                        {
                          field: 'actions',
                          headerName: 'Actions',
                          flex: 1,
                          renderCell: (params) => (
                            <div>

                              <VisibilityIcon
                                onClick={(e) => { viewMerit(params) }}
                                sx={{ mb: 2, cursor: 'pointer', fontSize: '1.1rem !important', m: 0.5, color: "grey" }}
                              />

                              <DeleteIcon
                                // onClick={} // Add your delete logic here
                                sx={{ mb: 2, cursor: 'pointer', fontSize: '1.1rem !important', m: 0.5, color: "red" }}
                              />

                            </div>
                          ),
                        }
                      ]}
                      getRowId={(row) => row.uuid}
                      loading={isLoading}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 25
                          },
                        },
                      }}
                      pageSizeOptions={[25, 50, 100]}
                      autoHeight
                      checkboxSelection={false} // Change as needed
                      disableRowSelectionOnClick={true} // Change as needed
                    />
                  </Grid>
                  )}
                </Grid>
              )}
            </MDBox>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Student;
