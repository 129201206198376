
import { useState, useEffect } from "react";
import "../../assets/theme-dark/base/colors";
import "../../assets/theme/base/colors";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "layouts/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";



function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["warning"];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? "#ffffff" : "#F7941D",
    color: darkMode ? "#081C3D" : "#ffffff",
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    marginX: "1vh",

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? "#E2E8F0" : "#F7941D",
      color: darkMode ? "#081C3D" : "#ffffff",
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? "#081C3D" : "#081C3D",
    color: darkMode ? "#ffffff" : "#ffffff",

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? "#081C3D" : "#F7941D",
      color: darkMode ? "#ffffff" : "#ffffff",
    },
  });

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        {/* <MDBox>
          <MDTypography variant="h5">Meritums</MDTypography>
          <MDTypography variant="body2" color="text">
            See our dashboard options.
          </MDTypography>
        </MDBox> */}

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>


        <MDBox mt={3} lineHeight={1}>
          <MDTypography variant="h6" fontWeight={500}>Theme</MDTypography>
          <MDTypography
            variant="button"
            color="text"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "50%", 
              fontWeight: 400 
            }}
          >
            See our possible themes.
          </MDTypography>


          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,

            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={() => {
                if (!darkMode) {
                  handleDarkSidenav();
                  handleDarkMode();
                }
              }}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              Dark
            </MDButton>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={() => {
                if (darkMode) {
                  handleDarkSidenav();
                  handleDarkMode();
                }
              }}
              disabled={disabled} 
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              White
            </MDButton>
             
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,

            }}
          >
            <MDTypography
              variant="button"
              color="dark"
              style={{
                whiteSpace: "normal", // Allow text to wrap to the next line
                width: "100%",
                fontWeight: 400 
              }}
            >
              If you wish to permanently save it, go to your profile.
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider />



      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
