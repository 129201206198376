import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { getItem } from "util/api.svs"

const ApiContext = createContext();

import {
    useMaterialUIController,
    setDarkMode,
} from "context";

const ApiProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [controller, dispatch] = useMaterialUIController();

    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = async () => {
        setIsLoading(true);
       try {

            const user = await Auth.currentAuthenticatedUser();
            const { signInUserSession: { idToken: { payload } } } = user;
         
            // Output user claims
            console.log('User claims:', payload);
            const schools = JSON.parse(payload.schools);
            console.log('schools:', schools);
            setUserData(payload)
            console.log(schools.length)
            if (schools.length >= 1) {
                setSchoolData(schools[0])
            }
            
        } catch (err) {
            console.log(err)
            console.log('User is not logged in.'); // User is not logged in
        }
        setTimeout(() => {
            console.log("here")
            setIsLoading(false);
        }, 1500);
    };


    return (
        <ApiContext.Provider value={{ userData, schoolData, isLoading, error, fetchData }}>
            {children}
        </ApiContext.Provider>
    );
};

export { ApiContext, ApiProvider };