import React from 'react';
import '../pages.css';
import "assets/styles/tailwind.css";
import './index.css';

// import IndexNavbar from "./IndexNavbar";
import Footer from "layouts/PublicFooter";
import PublicNavbar from "layouts/Navbars/PublicNavbar";

import meritumsOrange from '../../assets/images/meritums/Meritums_orange.jpg';
import meritumsBlue from '../../assets/images/meritums/Meritums_blue.jpg';
import logo from '../../assets/images/gistics-full.png'

export default function Anything() {

  return (
    <>
    <PublicNavbar />
    <section className="header relative pt-16 items-center flex flex-col justify-center h-screen max-h-860-px landing">
      <div className="container mx-auto items-center flex flex-col justify-center">
        <img
          className="mx-auto w-8/12 sm:w-6/12"
          src={meritumsBlue}
          alt="..."
        />
        <div className="text-center mt-0">
          <p className="mt-4 mb-6 text-lg leading-relaxed text-white" style={{ fontFamily: "Poppins"}}>
            Helps manage your points
          </p>
  
          <div className="mt-2 mb-12">
            <a
              href="/auth/login"
              className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-4 mb-8 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 getStarted"
            >
              Get started
            </a>
  
            <a
              href="/contact"
              className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none ml-4 mr-0 mb-1 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 getInTouch"
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
    </section>
  
    <Footer />
  </>
  
  );
}

