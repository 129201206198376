import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState, useContext } from "react";
import { ThreeCircles } from 'react-loader-spinner'
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import * as Sentry from "@sentry/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import MDSnackbar from "components/MDSnackbar";

// Data
import { getItem } from "util/api.svs"
import { postItem, deleteItem } from "util/api.svs";

import {
  useMaterialUIController,
} from "context";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

function Merits() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const context = useContext(ApiContext);
  const { isLoading, userSelectedBusiness, userData } = context;

  const [IsLoaded, setIsLoaded] = useState(false);
  const [rows, setRow] = useState([]);
  
// Alerts

const [alertType, setAlertType] = useState("success");
const [alertIcon, setAlertIcon] = useState("");
const [alertTitle, setAlertTitle] = useState("");
const [alertMessage, setAlertMessage] = useState("");
const [openAlert, setOpenAlert] = useState(false);
const alertAction = () => setOpenAlert(false);

const openAlertFunction = (type, icon, title, message) => {
  setAlertType(type)
  setAlertIcon(icon)
  setAlertTitle(title)
  setAlertMessage(message)
  setOpenAlert(true)

};

const renderAlert = (
  <MDSnackbar
    color={alertType}
    icon={alertIcon}
    title={alertTitle}
    content={alertMessage}
    dateTime=""
    open={openAlert}
    onClose={alertAction}
    close={alertAction}
    bgWhite
  />
);


 

  const manageClick = (email, object) => {
    object.email = email
    setUpdateFormData(object)
    // setOpen(true);
    handleOpenUpdateModal()
  };


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputUpdateChange = (e) => {
    setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add row data to the rows array state
    console.log("Checking:", formData.email)
    console.log("Checking:", formData)
    if (formData.email === '') {
      console.log("here")
      // Handle validation errors (you can replace this with your error handling logic)
      // notify("tl", "Please make sure you selected all fields.", "warning");
      openAlertFunction(
        "warning",
        "warning",
        "Required information",
        "Please make sure you selected all fields."
      );

    } else {
      console.log("wtf")
      // Validation passed, add data to the inviteRows state
      setInviteRows([...inviteRows, formData]);
  
      // Reset the form fields
      setFormData({ email: '', role: 'General' });
    }
  };

  const cancelInvite = (e) => {
    setInviteRows([])
    handleCloseModal()
  }

  const cancelManage = (e) => {
    handleCloseUpdateModal()
  }

  const handleRemoveRow = (index) => {
    const updatedRows = [...inviteRows];
    updatedRows.splice(index, 1);
    setInviteRows(updatedRows);

  };

  const handleSendingInvites = async (e) => {
    setIsLoaded(false)
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const formattedInviteRows = inviteRows.reduce((acc, row) => {
      acc[row.email] = { group: row.group };
      return acc;
    }, {});
    postItem(`/user/${userSelectedBusiness.business_id}/invite`, formattedInviteRows, token).then((resp) => {
      setInviteRows([])
      handleCloseModal()
      setIsLoaded(true)
      openAlertFunction(
        "success",
        "success",
        "Invites sending...",
        "Once the invites has been sent you can come back and the team will be visible."
      );
    }
    ).catch((err) => {
      Sentry.captureException(err)
      setIsLoaded(true)
      openAlertFunction(
        "errorr",
        "errorr",
        "Invites could not be sent.",
        "Our team has been notified. You can try again or reach out to us if it persists."
      );
    })
  };


  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  useEffect(() => {

    async function fetchData() {
    
      await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      getItem(`/users/merit?status=pending`, token).then(resp => {
        console.log(resp.data)
        setRow(resp.data)
        setIsLoaded(true)
      }).catch(error => {
        Sentry.captureException(error)
        setIsLoaded(true)
      })
    }
    if (!isLoading) {
      fetchData();
    }
  }, [isLoading])

  const navigate = useNavigate();

  const assignMerit = () => {
    navigate("/merits-asign")
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />


      <MDBox pt={6} pb={3}>
        {IsLoaded ? (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card sx={{backgroundColor: "#B2BFD0"}}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{ backgroundColor: "#F7941D"}}
                  >
                    <MDTypography variant="h6" sx={{ color: "#fff", fontWeight: 600, fontFamily: "Poppins", fontSize: "1.1rem" }}>
                      Merits Approvals
                    </MDTypography>
                  </MDBox>

                  <MDBox pt={3}>
                  <DataGrid
                       sx={{
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                          color: '#081c3d',
                          fontWeight: 700,
                        },
                      }}
                        rows={rows}
                        columns={[
                          { field: 'student_display_name', headerName: 'Student', flex: 1 },
                          { field: 'Point', headerName: 'Point', flex: 1  ,renderCell: (params) => {
                            return params.row.total.toString()
                          } },
                          { field: 'reason', headerName: 'Reason', flex: 1 },
                    
                          {
                            field: 'actions',
                            headerName: 'Actions',
                            flex: 1,
                            renderCell: (params) => (
                              <div>
                                {userData.is_admin ||  serData.is_approver ? ( // Check if user is admin
                                  <MDButton
                                    variant="contained"
                                    onClick={(e) => {assignMerit(params)}}
                                    color="warning"
                                    sx={{ mb: 2, mx: 2 }}
                                  >
                                    Approve
                                  </MDButton>
                                ) : null}
                  
                                <MDButton
                                  variant="contained"
                                  // onClick={}
                                  color="warning"
                                  sx={{ mb: 2 }}
                                >
                                  Decline
                                </MDButton>
                                {/* Render the button only if user is admin */}
                              </div>
                            ),
                          }
                        ]}
                        getRowId={(row) => row.uuid}
                        loading={!IsLoaded}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 25
                            },
                          },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        autoHeight
                        checkboxSelection={false} // Change as needed
                        disableRowSelectionOnClick={true} // Change as needed
                      />
                  </MDBox>
                </Card>
              </Grid>

            </Grid>
          </>)
          : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              /></div>

          )
        }
      </MDBox>
      
      {renderAlert}
      <Footer />
    </DashboardLayout>
  );
}

export default Merits;
