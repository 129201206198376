import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import EditIcon from '@mui/icons-material/Edit';
import { Auth } from 'aws-amplify';
// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { FormControl, IconButton, Input, InputLabel, Button } from "@mui/material";
import MDButton from "components/MDButton";

import { ApiContext } from 'context/meritiumUser';
import { updateItem } from "util/api.svs"

function ProfileInfoCard() {
  const [editing, setEditing] = useState(false)


  const context = useContext(ApiContext);
  const { isLoading, userBusinesses, userData, fetchData } = context

  const [editedFirstName, setEditedFirstName] = useState(userData?.first_name || '');
  const [editedLastName, setEditedLastName] = useState(userData?.surname || '');
  const [editedPhoneNumber, setEditedPhoneNumber] = useState(userData?.phone_number || '');


  const cancelEdit = () => {
    setEditedFirstName(userData?.first_name || '')
    setEditedLastName(userData?.surname || '')
    setEditedPhoneNumber(userData?.phone_number || '')
    setEditing(false)
  }

  const updateInformation = async () => {
    console.log("updating user")
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    const data = {
      first_name: editedFirstName,
      surname: editedLastName,
      phone_number: editedPhoneNumber,
    }
    updateItem(`/profile/`, data, token).then(resp => {
      console.log("Update Resp", resp)
      fetchData()
      setEditing(false)
    }).catch(resp => {
      console.log(resp)
    })

  }



  return (
    <Card style={{ height: "100%", width: '100%', boxShadow: "none" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
          Profile Information
        </Typography>
        <Typography variant="body2" color="secondary">
          <Tooltip title={"Edit"} placement="top">
            <IconButton onClick={() => { setEditing(true) }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>
      <Box p={2}>
        {userData === null ? (<>
          <Box>
            <Box display="flex" py={1} pr={2}>

              <MDBox ml="auto" display="flex" flexDirection="column" justifyContent="center" >
                <MDTypography variant="button" fontWeight="medium">
                  Sorry couldnt find you
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  We could not load your user data...
                </MDTypography>
              </MDBox>

            </Box>
          </Box>
        </>) : (<>
          <Box>
            <Box display="flex" py={1} pr={2}>
              {editing ? (
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">First Name</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" value={editedFirstName}
                    onChange={(e) => setEditedFirstName(e.target.value)} />
                </FormControl>
              ) : (
                <>
                  <FormControl style={{ width: "90%" }}>
                    <InputLabel htmlFor="my-input">First Name</InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" value={editedFirstName} disabled />
                  </FormControl>
                </>
              )}
            </Box>
            <Box display="flex" py={1} pr={2}>
              {editing ? (
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">Last Name</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" value={editedLastName}
                    onChange={(e) => setEditedLastName(e.target.value)} />
                </FormControl>
              ) : (
                <>
                  <FormControl style={{ width: "90%" }}>
                    <InputLabel htmlFor="my-input">Last Name</InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" value={editedLastName} disabled />
                  </FormControl>
                </>
              )}
            </Box>
            <Box display="flex" py={1} pr={2}>
              {editing ? (
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" value={editedPhoneNumber}
                    onChange={(e) => setEditedPhoneNumber(e.target.value)} />
                </FormControl>
              ) : (
                <>
                  <FormControl style={{ width: "90%" }}>
                    <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" disabled value={editedPhoneNumber} />
                  </FormControl>
                </>
              )}
            </Box>
            <Box display="flex" py={1} pr={2}>
              {editing && (
                <>
                  <Button variant="contained" style={{ backgroundColor: "grey", color: "white", marginRight: "5px", width: "5vw" }} onClick={cancelEdit}>Cancel</Button>
                  <MDButton variant="gradient" color="warning" style={{ marginRight: "5px", width: "5vw" }} onClick={updateInformation}>Save </MDButton>
                </>
              )}
            </Box>

          </Box>
        </>)}

      </Box>
    </Card>
  );
}


export default ProfileInfoCard;