import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import { ThreeCircles } from 'react-loader-spinner';
import { getItem, postItem } from "util/api.svs";
import { TextField, Checkbox, FormControlLabel, Autocomplete, Select, MenuItem, IconButton } from '@mui/material';
import MDInput from "components/MDInput";
import { useLocation, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import MDSnackbar from "components/MDSnackbar";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

const StyledTotalTextField = styled(TextField)(({ theme }) => ({
  height: "6vh !important",
  "& .MuiInputBase-root": {
    height: "6vh !important",
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
  },
   // Styles for focused state
   '& .MuiOutlinedInput-root': {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F7941D',
        borderWidth: '2px',
        color: "#f7941d"
      },
    },
    "input:focus + fieldset label":{
      "input:not(:placeholder-shown) + fieldset label": {
        "input:active + fieldset label": {
          color: "#F7941D"
        }
      }
      },
    },
  // Styles for active and selected state
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F7941D !important',
    borderWidth: '2px',
    color: "#f7941d"
  },
}));

function AssignMerrit() {

  const { id } = useParams();
  const context = useContext(ApiContext);
  const { isLoading: contextIsLoading, userData, schoolData } = context;

  const [selectedGrade, setSelectedGrade] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);

  const [formData, setFormData] = useState({
    students: [{ student_id: "", student_display_name: "" }], // Assuming studentID will be selected from dropdown
    merit_id: "", // Assuming meritID will be selected from dropdown
    reason: "",
    total: 0,
    merit: false,
    processed: false,
    status: "",
    auto_approve: (userData.is_admin || userData.is_approver) ? true : false, // Convert to boolean
    notes: ""
  });
  const [students, setStudents] = useState([]); // State to hold students data from API
  const [meritPoints, setMeritPoints] = useState([]); // State to hold merit points data from API
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const [studentsResponse, meritPointsResponse] = await Promise.all([
          getItem(`/students`, token),
          getItem("/merit-template", token) // Adjust the API endpoint accordingly
        ]);
        console.log(studentsResponse.data)
        console.log(meritPointsResponse.data)
        const sortedRows = sortRowsAlphabetically(studentsResponse.data, 'first_name');

        setStudents(sortedRows);
        setMeritPoints(meritPointsResponse.data);

        // See if id was passed to assign to first user
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
    if (!contextIsLoading) {
      fetchData();
    }
  }, [contextIsLoading]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("Setting", name, "with ", value)
    setFormData({ ...formData, [name]: value });
  };

  const handleStudentChange = (event, value, index) => {
    if (value) {
      // Assuming value is the selected student object
      console.log(value, "-> Got")
      const selectedStudent = {
        student_id: value.uuid,
        student_display_name: `${value.first_name} ${value.last_name} - Grade ${value.grade}`
      };
  
      // Filter out the selected student from filteredRows
      const updatedFilteredRows = filteredRows.filter(student => student.uuid !== value.uuid);
      setFilteredRows(updatedFilteredRows);
  
      // Update the form data with the selected student
      setFormData(prevState => {
        const updatedStudents = [...prevState.students];
        updatedStudents[index] = selectedStudent;
        return { ...prevState, students: updatedStudents };
      });
    }
  };

  const handleGradeFilter = (event) => {
    const selectedGrade = event.target.value;
    setSelectedGrade(selectedGrade);

    // Filter rows based on selected grade
    if (selectedGrade === "") {
      setFilteredRows(students); // Reset to all rows if no grade selected
    } else {
      const filtered = students.filter(row => row.grade === selectedGrade);
      setFilteredRows(filtered);
    }
  };

  const [customMerit, setCustomMerit] = useState(false);
  const [autoApprove, setAutoApprove] = useState((userData.is_admin || userData.is_approver));

  const sortRowsAlphabetically = (rows, column) => {
    return rows.slice().sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });
  };

  const customMeritSelect = (event) => {
    setCustomMerit(event.target.checked)
  };

  const setAutoApproveSelect = (event) => {
    setAutoApprove(event.target.checked)
  };

  const meritHandle = (event, value) => {
    console.log("Value for the chosen data is as follows", value)
    let selectedMeritPointTotal = 0;
    let selectedReason = "";
    if (value) {
      selectedReason = value.title
      if (!value.merit) {
        selectedMeritPointTotal = - value.default_value;
      } else {
        selectedMeritPointTotal = value.default_value;
      }

    }


    setFormData(prevState => ({
      ...prevState,
      merit_id: value ? value.uuid : "", // Set merit_id to selected merit point's UUID or empty string if nothing selected
      total: selectedMeritPointTotal,// Set total to selected merit point's pointTotal
      reason: selectedReason
    }));
  };

  const submitMerit = async () => {
    setIsLoading(true)
    try {
      console.log("Before filtering students:", formData.students);

      // Filter out students with empty IDs and names
      const filteredStudents = formData.students.filter(student => student.student_id !== "" && student.student_display_name !== "");

      console.log("After filtering students:", filteredStudents);

      // Call the API function to post the form data with filtered students
      const formDataWithFilteredStudents = {
        ...formData,
        students: filteredStudents
      };

      // Call the API function to post the form data
      const formDataWithIntegerTotal = {
        ...formDataWithFilteredStudents,
        total: parseInt(formDataWithFilteredStudents.total)
      };
      await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      console.log("Form data submitted:", formDataWithIntegerTotal);
      const response = await postItem("/users/merit", formDataWithIntegerTotal, token);
      console.log(response)
      setFormData({
        students: [], // Assuming studentID will be selected from dropdown
        merit_id: "", // Assuming meritID will be selected from dropdown
        reason: "",
        givenBy: "",
        total: 0,
        merit: false,
        processed: false,
        status: "",
        approvedBy: "",
        notes: ""
      })
      // Handle success or display alert
      setIsLoading(false)
      openAlertFunction("success", "success", "Merits are being processed", "We have received your points and they are being processed")
    } catch (error) {
      // Handle error or display alert
      console.error("Error submitting form:", error);
      setIsLoading(false)
      openAlertFunction("failed", "we could not process this request", "Merits are not being processed", "Something went wrong. Contact the team to have a look")
    }
  };

  const addStudent = () => {
    // Add an empty student object to formData.students
    setFormData(prevState => ({
      ...prevState,
      students: [...prevState.students, { student_id: "", student_display_name: "" }]
    }));
  };
  
  const handleRemoveStudent = (index) => {
    setFormData(prevState => ({
      ...prevState,
      students: prevState.students.filter((student, i) => i !== index)
    }));
  };
  


  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          {isLoading ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              />
            </div>
          ) : (
            <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ position: "absolute", left: 10, top: 8, bottom: 0}}>
                  {/* Back Arrow Link */}
                  <IconButton sx={{ marginRight: '10px', fontSize: "2rem" }} component={Link} to="/students">
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>

              <MDTypography variant="h6" mb={3}>
                Assign merits
              </MDTypography>
              <form style={{ width: '100%' }}>
                <Grid container spacing={3}>

                  {(userData.is_admin || userData.is_approver) && (
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={autoApprove} onChange={setAutoApproveSelect} name="auto_approve" />
                        }
                        label="Auto Approve"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={customMerit} onChange={customMeritSelect} name="custom" />
                      }
                      label="Custom merit"
                    />
                  </Grid>


                  {!customMerit ? (
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        name="merit_id"
                        options={meritPoints}
                        getOptionLabel={(meritPoint) => meritPoint.title}
                        value={meritPoints.find(point => point.uuid === formData.merit_id) || null}
                        onChange={meritHandle}
                        renderInput={(params) => (
                          <StyledTotalTextField
                            {...params}
                            label="Merit Point"
                            InputLabelProps={{ id: 'meritID-label' }}
                            id="merit_id"
                            name="merit_id"
                            sx={{
                              "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                                borderColor: "#F7941D"
                              },
                              "& .MuiInputBase.Mui-focused": {
                                border: "2px solid #F7941D !important",
                                color: "#F7941D"
                              },
                              "& label.Mui-focused": {
                                  color: "#081c3d",
                                  color: "#F7941D"
                              },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#F7941D !important",
                                    color: "#F7941D"
                                  }
                                }
                              }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (

                    <Grid item xs={6}>
                      <StyledTotalTextField
                        name="reason"
                        label="Reason"
                        value={formData.reason}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        sx={{
                          "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                            borderColor: "#F7941D"
                          },
                          "& .MuiInputBase.Mui-focused": {
                            border: "2px solid #F7941D !important",
                            color: "#F7941D"
                          },
                          "& label.Mui-focused": {
                              color: "#081c3d",
                              color: "#F7941D"
                          },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#F7941D !important",
                                color: "#F7941D"
                              }
                            }
                          }}
                      />
                    </Grid>

                  )}

                  <Grid item xs={6}>
                    <StyledTotalTextField
                      name="total"
                      label="Total"
                      value={formData.total}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      type="number"
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StyledTotalTextField
                      name="notes"
                      label="Notes"
                      value={formData.notes}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>

                  <Grid item xs={12}  md={6} sx={{ margin: "auto" }}>
                    {/* Dropdown for grade filtering */}
                    <Select
                      value={selectedGrade || ''}
                      onChange={handleGradeFilter}
                      fullWidth
                      displayEmpty
                      style={{
                        fontSize: "1rem",
                        padding: 3,
                        '&:focus': {
                          border: '2px solid #F7941D',
                        },
                      }}
                    >
                      <MenuItem value={""}>All Grades</MenuItem>
                      <MenuItem value={"R"}>Grade R</MenuItem>
                      <MenuItem value={"1"}>Grade 1</MenuItem>
                      <MenuItem value={"2"}>Grade 2</MenuItem>
                      <MenuItem value={"3"}>Grade 3</MenuItem>
                      <MenuItem value={"4"}>Grade 4</MenuItem>
                      <MenuItem value={"5"}>Grade 5</MenuItem>
                      <MenuItem value={"6"}>Grade 6</MenuItem>
                      <MenuItem value={"7"}>Grade 7</MenuItem>

                      {/* Add more grade options */}
                    </Select>
                  </Grid>

                      
                  {formData.students.slice(1).map((studentId, index) => (
                    <Grid container item xs={12} md={6} alignItems="center" justifyContent="space-between" key={index}>
                      <Grid item xs={9}>
                        <Autocomplete
                          fullWidth
                          id={`student_${index}`}
                          options={filteredRows}
                          getOptionLabel={(student) => `${student.first_name} ${student.last_name} - Grade ${student.grade}`}
                          sx={{
                            "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                              borderColor: "#009688"
                            },
                            "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#009688 !important"
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "#009688"
                            }
                          }}
                          value={students.find(student => student.uuid === studentId)} // Set the value based on formData.student_id
                          onChange={(event, value) => handleStudentChange(event, value, index)} // Pass index to handleStudentChange
                          renderInput={(params) => <TextField {...params} label="Student" />}
                        />
                      </Grid>

                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <IconButton onClick={() => handleRemoveStudent(index)}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <MDButton onClick={addStudent} 
                      sx={{
                        backgroundColor: "#F7941D", color: "#fff", width: "10vw",
                          "&: hover": {
                            backgroundColor: "#E2E8F0",
                            color: "#081C3D"
                          },
                          "&:focus": {
                            backgroundColor: "#F7941D",
                            color: "#081c3d",
                          },
                          "&:active": {
                            backgroundColor: "#F7941D",
                            color: "#081c3d",
                          }
                      }}
                    > 
                      + Add Student 
                    </MDButton>
                  </Grid>



                  {/* Add more fields as needed */}
                  <Grid item xs={12}>
                    <MDButton onClick={submitMerit} sx={{
                      backgroundColor: "#F7941D", color: "#fff", width: "10vw",
                      "&: hover": {
                        backgroundColor: "#E2E8F0",
                        color: "#081C3D"
                      }
                    }}>
                      Save
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            </MDBox>


          )}
          {renderAlert}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignMerrit;
