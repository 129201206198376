
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Authenticator } from '@aws-amplify/ui-react';
import { ApiProvider } from './context/meritiumUser';
import * as Sentry from "@sentry/react";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";


Sentry.init({
  dsn: "https://1ee3c5744ff1978994245af011fcf553@o796007.ingest.sentry.io/4506813660528640",
  integrations: [
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_STAGE
});

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Authenticator.Provider>
        <ApiProvider>
          <App />
        </ApiProvider>
      </Authenticator.Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
