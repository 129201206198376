import React, { useState, useContext } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ApiContext } from "context/meritiumUser";
import { updateItem } from "util/api.svs";
import { Auth } from "aws-amplify";
import { useMaterialUIController, setDarkMode } from "context";
import * as Sentry from "@sentry/react";

function PlatformSettings() {
  const context = useContext(ApiContext);
  const { isLoading, userData } = context;

  const [lightTheme, setLightTheme] = useState(userData.light_theme);
  const [productUpdate, setProductUpdate] = useState(userData.newsletter);
  const [newsletter, setNewsletter] = useState(userData.newsletter);

  const [controller, dispatch] = useMaterialUIController();


  
  const toggleTheme = () => {
    setLightTheme((prevLightTheme) => {
      // Update dark theme immediately after toggling the light theme
      return !prevLightTheme; // Return the new value for light theme
    });
    setDarkMode(dispatch, lightTheme);
    updateInformation(!lightTheme); // Pass the updated value to the function
  };

  const updateInformation = async (updatedLightTheme) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken();
    const data = {
      light_theme: updatedLightTheme, // Use the updated value here
      product_updates: productUpdate,
      newsletter: newsletter,
    };
    updateItem(`/profile/settings`, data, token)
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={lightTheme} onChange={toggleTheme} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Dark/Light
            </MDTypography>
          </MDBox>
        </MDBox>

        {/* <MDBox mt={3}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            notifications
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={productUpdate} onChange={() => { setProductUpdate(!productUpdate), updateInformation(lightTheme) }} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Monthly product updates
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={newsletter} onChange={() => { setNewsletter(!newsletter), updateInformation(lightTheme) }} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </MDTypography>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
