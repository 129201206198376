import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState, useContext } from "react";
import { ThreeCircles } from 'react-loader-spinner'
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as Sentry from "@sentry/react";
import { styled } from "@mui/material/styles";
import { TextField, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

const StyledTotalTextField = styled(TextField)(({ theme }) => ({
  height: "6vh !important",
  "& .MuiInputBase-root": {
    height: "6vh !important",
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px"
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import MDSnackbar from "components/MDSnackbar";

// Data
import { getItem } from "util/api.svs"
import { postItem, deleteItem } from "util/api.svs";

import {
  useMaterialUIController,
} from "context";
import { DataGrid } from "@mui/x-data-grid";
import MDInput from "components/MDInput";

function MeritsTemplatesAdd() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const context = useContext(ApiContext);
  const { isLoading } = context;

  const [IsLoaded, setIsLoaded] = useState(false);
  const [rows, setRow] = useState([]);

  // Alerts

  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  // App Specific
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [defaultValue, setDefaultValue] = useState(0);
  const [merit, setMerit] = useState(true);

  const handleSubmit = async () => {
    try {
      setIsLoaded(false);
      const defaultValueNumber = parseInt(defaultValue, 10);
      // Construct the object based on form state
      const formData = {
        title: title,
        description: description,
        default_value: defaultValueNumber,
        merit: merit
      };

      // Call the API function to post the form data
      await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await postItem("/admin/merit-template", formData, token);
      setTitle("");
      setDescription("");
      setDefaultValue(0);
      setMerit(true);

      // Reset loading state
      setIsLoaded(true);
      // Handle success or display alert
      console.log("Form data submitted:", response.data);
    } catch (error) {
      // Handle error or display alert
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {

    async function fetchData() {

      await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      getItem(`/admin/students`, token).then(resp => {
        console.log(resp.data)
        setRow(resp.data)
        setIsLoaded(true)
      }).catch(error => {
        Sentry.captureException(error)
        setIsLoaded(true)
      })
    }
    if (!isLoading) {
      fetchData();
    }
  }, [isLoading])

  return (
    <DashboardLayout>
      <DashboardNavbar />


      <MDBox pt={6} pb={3}>
        <Card>
          {IsLoaded ? (
            <>
              <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ position: "absolute", left: 10, top: 8, bottom: 0}}>
                  {/* Back Arrow Link */}
                  <IconButton sx={{ marginRight: '10px', fontSize: "2rem" }} component={Link} to="/merit-templates">
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>

                <MDTypography variant="h6" mb={3} sx={{ color: "#F7941D", fontSize: "1.5rem", fontWeight: 500}}>
                  Create Template
                </MDTypography>
                <Grid container spacing={3} sx={{ display: "flex", justifyContent: "center" }}>
                  <Grid item xs={10}>
                    <StyledTotalTextField
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      fullWidth
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <StyledTotalTextField
                      label="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <StyledTotalTextField
                      type="number"
                      label="Default Value"
                      value={defaultValue}
                      onChange={(e) => setDefaultValue(e.target.value)}
                      fullWidth
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <InputLabel>Merit</InputLabel>
                    <Select
                      value={merit}
                      onChange={(e) => setMerit(e.target.value)}
                      sx={{ width: "6vw", height: "4vh", padding: "0.5vh", marginY: "1vh" }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Button variant="contained" onClick={handleSubmit} sx={{
                      backgroundColor: "#F7941D", color: "#fff", fontWeight: 600, fontFamily: "Poppins", "&: hover": {
                        backgroundColor: "#E2E8F0 !important",
                        color: "#081C3D"
                      }
                    }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                </MDBox>
              </>)
              : (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
              }}>
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor="#FFDD00"
                  innerCircleColor="#000000"
                  middleCircleColor="#737373"
                />
              </div>
              )
        }
            </Card>
      </MDBox>

      {renderAlert}
      <Footer />
    </DashboardLayout>
  );
}

export default MeritsTemplatesAdd;
