

// @mui material components
import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import "../../assets/styles/tailwind.css";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ReportsBarChart from "layouts/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "layouts/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "pages/dashboard/data/reportsBarChartData";
import reportsLineChartData from "pages/dashboard/data/reportsLineChartData";

// Dashboard components
import OrdersOverview from "pages/dashboard/components/OrdersOverview";

import { Auth } from 'aws-amplify';
import { getItem, postItem, deleteItem, updateItem } from "util/api.svs";
import MDSnackbar from "components/MDSnackbar";
import { ThreeCircles } from 'react-loader-spinner'
import * as Sentry from "@sentry/react";
import { ApiContext } from 'context/meritiumUser';

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const context = useContext(ApiContext);
  const { isLoading, schoolData } = context;

  const defaultData = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "No Data", data: [0, 1, 0, 0, 0, 0, 0, 0, 0] },
  };


  const divStyle = {
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#E2E8F0',
    color: 'rgba(73, 77, 104, 1)'
  };


  const [IsLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [pointData, setPointData] = useState({});

  const [elapsedTime, setElapsedTime] = useState(0);



  useEffect(() => {
    const fetchData = async () => {
      if (schoolData) {
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        // Get stats
        setIsLoaded(false);
        try {
          getItem(`/users/stats/students`, token)
            .then(resp => {
              const gradeCounts = resp.data.reduce((acc, obj) => {
                const grade = Object.keys(obj)[0];
                const count = obj[grade];
                // Aggregate counts by grade
                acc[grade] = (acc[grade] || 0) + count;
                return acc;
              }, {});

              const transformedData = {
                labels: Object.keys(gradeCounts), // Grades as labels
                datasets: {
                  label: "Number of Students", // Set label
                  data: Object.values(gradeCounts) // Use counts as data
                }
              };
              console.log(transformedData);

              setStudentData(transformedData);
              getItem(`/users/stats/points`, token)
                .then(respP => {
                  console.log("Points")
                  // Convert the month-year keys to an array of objects
                  const pointDataArray = Object.keys(respP.data).map(key => ({
                    monthYear: key,
                    count: respP.data[key]
                  }));

                  // Sort the array of objects by month-year in ascending order
                  pointDataArray.sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));

                  // Extract labels and data from the sorted array
                  const labels = pointDataArray.map(item => item.monthYear);
                  const data = pointDataArray.map(item => item.count);

                  // Create the transformed data object
                  const transformedData = {
                    labels: labels, // Month-year as labels
                    datasets: {
                      label: "Number of Points", // Set label
                      data: data // Use counts as data
                    }
                  };

                  console.log(transformedData);
                  setPointData(transformedData);
                  setIsLoaded(true);
                })
                .catch(error => {
                  Sentry.captureException(error);
                  console.log(error);
                  setIsLoaded(true);
                });

            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
              setIsLoaded(true);
            });
        } catch (error) {
          Sentry.captureException(error);
          openAlertFunction(
            "error",
            "error",
            "Issue collecting stats",
            "We could not get your data at the moment and will have to get back to you."
          );
          const now = new Date();
          const loadTime = new Date(localStorage.getItem('loadTime'));
          const diffInMinutes = Math.floor((now - loadTime) / (1000 * 60));
          setElapsedTime(diffInMinutes);
          setIsLoaded(true);
        }
      } else {
        setIsLoaded(true);
      }
    };

    if (!isLoading) {
      fetchData();
    }

    const interval = setInterval(() => {
      const now = new Date();
      const loadTime = new Date(localStorage.getItem('loadTime'));
      const diffInMinutes = Math.floor((now - loadTime) / (1000 * 60));
      setElapsedTime(diffInMinutes);
    }, 60000);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    // Save the load time in the local storage when the component mounts
    localStorage.setItem('loadTime', new Date().toString());
  }, []);



  // Alerts

  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={12}>
        {IsLoaded ? (
          <>
            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                {
                  // Replace
                  schoolData === null ? (<>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ height: '70vh' }}
                      >
                        <Grid item>
                          <div style={divStyle}>
                            <h2 className="meritums-text-grey text-base font-semibold footer-text">You have no linked children or schools.</h2>
                            <br></br>
                            <p className="meritums-text-grey text-base font-semibold footer-text">If this is a mistake contact us or your admins to invite you to join the school or add you as a parent. </p>

                            <br></br>
                            <p className="meritums-text-grey text-base font-semibold footer-text">Or you can register a school here. </p>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>
                  </>
                  ) : (
                    <>
                      <>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={3}>
                            <ReportsBarChart
                              color="info"
                              title="Students"
                              description={
                                <>
                                  Student totals
                                </>
                              }
                              date={elapsedTime + " min ago"}
                              chart={studentData}
                            />
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={3}>
                            <ReportsLineChart
                              color="info"
                              title="Merits"
                              description={
                                <>
                                  Merits Given
                                </>
                              }
                              date={elapsedTime + " min ago"}
                              chart={pointData}
                            />
                          </MDBox>
                        </Grid>
                      </>
                    </>
                  )

                }

              </Grid>
            </MDBox>
          </>)
          : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              /></div>

          )
        }
      </MDBox>
      {renderAlert}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
