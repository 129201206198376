import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Auth } from 'aws-amplify';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as Sentry from "@sentry/react";
import backgroundImageDefault from "assets/images/bg-profile.jpeg";
import { updateFileItem } from "util/api.svs"
import MDSnackbar from "components/MDSnackbar";
import { ApiContext } from 'context/meritiumUser';

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const context = useContext(ApiContext);
  const { isLoading, userData } = context;
  const [userEmail, setUserEmail] = useState('');


  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserEmail(user.attributes.email);
      } catch (error) {
        Sentry.captureException(error)
      }
    };

    fetchUserEmail();

    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // Images
  const [profilePicture, setProfilePicture] = useState(`${process.env.REACT_APP_IMAGE_BASE}/${userData?.profile_image}` || '');

  const updateProfilePicture = async (event) => {
    console.log("Uploading....");
    if (event.target.files.length > 0) {
      console.log("Working on it....");
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        setProfilePicture(e.target.result);
      };
  
      // Create a Blob object from the file
      const blob = new Blob([file], { type: file.type });
  
      // Read the data URL from the Blob object
      reader.readAsDataURL(blob);
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
  
      updateFileItem("/profile/profile", file, token)
        .then((resp) => {
          console.log(resp);
          openAlertFunction("success", "success", "Updated", "We have updated your image")
        })
        .catch((error) => {
          openAlertFunction("warning", "warning", "Issue changing logo", "We could not upload your image.")
          Sentry.captureException(error);
        });
    }
  };

  const [backgroundImage, setBackgroundImage] =  useState(`${process.env.REACT_APP_IMAGE_BASE}/${userData?.background_image}` || '');

  const updateBackground = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        setBackgroundImage(e.target.result);
      };

      // Create a Blob object from the file
      const blob = new Blob([file], { type: file.type });

      // Read the data URL from the Blob object
      reader.readAsDataURL(blob);
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      updateFileItem("/profile/background", file, token).then((resp) => {
        console.log(resp)
        openAlertFunction("success", "success", "Updated", "We have updated your image")
        // fetchData()
    }).catch((error) => {
        Sentry.captureException(error)
        openAlertFunction("warning", "warning", "Issue changing logo", "We could not upload your image.")
    })
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage ? backgroundImage : backgroundImageDefault})`,
          // )}, url(${backgroundImage})`,
          // backgroundImageDefault
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}

        >
          <label htmlFor="backgroundInput">
            <EditIcon />
          </label>
          <input
            id="backgroundInput"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={updateBackground}
          />
        </IconButton>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}>
        <Grid container spacing={0} alignItems="center">
          <Grid item sx={2} lg={2}>
            <MDAvatar
              src={
                profilePicture
                  ? profilePicture
                  : "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
              }
              alt="profile-image"
              size="xl"
              shadow="sm"
            />

            <IconButton sx={{ position: "absolute", top: 60, left: 70 }}>
              <label htmlFor="profilePictureInput">
                <EditIcon />
              </label>
              <input
                id="profilePictureInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={updateProfilePicture}
              />
            </IconButton>
          </Grid>
          <Grid item sx={10} lg={10}>
            <MDBox height="100%" width="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium" style={{ maxWidth: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left' }}>
                {userData.first_name && userData.first_name.length > 15 ? `${userData.first_name.slice(0, 15)}...` : userData.first_name || userEmail}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
          </Grid>
        </Grid>
        {children}
      </Card>
      {renderAlert}
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;