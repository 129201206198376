
// @mui material components
import Card from "@mui/material/Card";
import { useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Auth } from 'aws-amplify';
import { useNavigate, Link } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";
import * as Sentry from "@sentry/react";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

function Forgot() {

  const navigate = useNavigate();

  const [codeSent, setCodeSent] = useState(false);
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleUsername = (event) => {
    setUsername(event.currentTarget.value)
  };


  const handleVerificationCode = (event) => {
    setVerificationCode(event.currentTarget.value)
  };

  const handlePassword = (event) => {
    setPassword(event.currentTarget.value)

  };
  const handlePasswordConfirmation = (event) => {
    setPasswordConfirmation(event.currentTarget.value)
  };

  const verificationHandler = async () => {
    if (password === passwordConfirmation) {
      await Auth.forgotPasswordSubmit(username, verificationCode, passwordConfirmation)
        .then((data) => {
          navigate('/profile')
        })
        .catch((err) => {
          Sentry.captureException(err)
          openAlertFunction("warning", "warning", "Issues resetting password", "We are having issues changing your password.")
        });
    }else{
      openAlertFunction("warning", "warning", "Passwords dont match", "Please make sure your passwords match.")
    }
   
  }

  const sendUserCode = async () => {
    await Auth.forgotPassword(username)
      .then((data) => {
        setCodeSent(true)
      })
      .catch((err) => {
        Sentry.captureException(err)
        openAlertFunction("warning", "warning", "Issue sending code", err.message)
      });

  }


  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        {!codeSent ? (
          <>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={4}>
                  <MDInput type="email" label="Email" variant="standard" fullWidth onChange={handleUsername} />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton variant="gradient" color="warning" fullWidth onClick={sendUserCode}>
                    reset
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        ) : (
          <>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput type="text" label="Verification Code" value={verificationCode} variant="standard" fullWidth onChange={handleVerificationCode} />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Password" variant="standard" fullWidth onChange={handlePassword} />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Confirm Password" variant="standard" fullWidth onChange={handlePasswordConfirmation} />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="warning" fullWidth onClick={verificationHandler}>
                    Reset
                  </MDButton>
                  
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="warning"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>

            </MDBox>
          </>
        )

        }
             {renderAlert}
      </Card>
    </CoverLayout>
  );
}

export default Forgot;
