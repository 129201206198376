import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import { Card } from "@mui/material";
import Footer from "layouts/Footer";
import MDButton from "components/MDButton";
import { Auth } from "aws-amplify";
import { postItem } from "util/api.svs";
import { ThreeCircles } from "react-loader-spinner";
import { styled } from "@mui/material/styles";


const StyledTotalTextField = styled(TextField)(({ theme }) => ({
  height: "6vh !important",
  "& .MuiInputBase-root": {
    height: "6vh !important",
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
  },
   // Styles for focused state
   '& .MuiOutlinedInput-root': {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F7941D',
        borderWidth: '2px',
      },
    },
  },
  // Styles for active and selected state
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F7941D !important',
    borderWidth: '2px',
  },
}));


function StudentForm() {
  const [students, setStudents] = useState([{ first_name: "", last_name: "", grade: "", gender: "" }]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSingleSubmit = async (event) => {
    event.preventDefault();
    
    // Check if any field is empty
    const isAnyFieldEmpty = students.some(student => Object.values(student).some(value => value === ""));
    if (isAnyFieldEmpty) {
      alert("Please fill out all fields.");
      return;
    }

    setIsLoading(true);
    try {
      await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await postItem("/admin/students/bulk", students, token);
      console.log(response);
      setStudents([{ first_name: "", last_name: "", grade: "", gender: "" }]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newStudents = [...students];
    newStudents[index] = { ...newStudents[index], [name]: value };
    setStudents(newStudents);
  };

  const handleAddStudent = () => {
    setStudents([...students, { first_name: "", last_name: "", grade: "", gender: "" }]);
  };

  const handleRemoveStudent = (index) => {
    const newStudents = [...students];
    newStudents.splice(index, 1);
    setStudents(newStudents);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          {!isLoading ? (
            <>
              <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box sx={{ width: "100%" }}>
                  <Box
                    component="form"
                    onSubmit={handleSingleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      p: 3,
                    }}
                  >
                    {students.map((student, index) => (
                      <Box key={index} sx={{ mb: 2, display: "flex", alignContent: "baseline" }}>
                        <StyledTotalTextField
                          label="First Name"
                          variant="outlined"
                          name="first_name"
                          value={student.first_name}
                          onChange={(event) => handleInputChange(index, event)}
                          sx={{
                            mb: 1, 
                            mx: 2,
                            "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                              borderColor: "#F7941D"
                            },
                            "& .MuiInputBase.Mui-focused": {
                              border: "2px solid #F7941D !important",
                              color: "#F7941D"
                            },
                            "& label.Mui-focused": {
                                color: "#081c3d",
                                color: "#F7941D"
                            },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#F7941D !important",
                                  color: "#F7941D"
                                }
                              }
                            }}
                        />

                        <StyledTotalTextField
                          label="Surname"
                          variant="outlined"
                          name="last_name"
                          value={student.last_name}
                          onChange={(event) => handleInputChange(index, event)}
                          sx={{
                            mb: 1, 
                            "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                              borderColor: "#F7941D"
                            },
                            "& .MuiInputBase.Mui-focused": {
                              border: "2px solid #F7941D !important",
                              color: "#F7941D"
                            },
                            "& label.Mui-focused": {
                                color: "#081c3d",
                                color: "#F7941D"
                            },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#F7941D !important",
                                  color: "#F7941D"
                                }
                              }
                            }}
                        />

                        <StyledTotalTextField
                          label="Grade"
                          variant="outlined"
                          name="grade"
                          value={student.grade}
                          onChange={(event) => handleInputChange(index, event)}
                          sx={{
                            mb: 1, 
                            mx: 2,
                            "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                              borderColor: "#F7941D"
                            },
                            "& .MuiInputBase.Mui-focused": {
                              border: "2px solid #F7941D !important",
                              color: "#F7941D"
                            },
                            "& label.Mui-focused": {
                                color: "#081c3d",
                                color: "#F7941D"
                            },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#F7941D !important",
                                  color: "#F7941D"
                                }
                              }
                            }}
                        />

                        <StyledTotalTextField
                          label="Gender"
                          variant="outlined"
                          name="gender"
                          value={student.gender}
                          onChange={(event) => handleInputChange(index, event)}
                         sx={{
                            mb: 1, 
                            "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                              borderColor: "#F7941D"
                            },
                            "& .MuiInputBase.Mui-focused": {
                              border: "2px solid #F7941D !important",
                              color: "#F7941D"
                            },
                            "& label.Mui-focused": {
                                color: "#081c3d",
                                color: "#F7941D"
                            },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#F7941D !important",
                                  color: "#F7941D"
                                }
                              }
                            }}
                        />

                        
                        {index > 0 && (
                          <MDButton
                            variant="contained"
                            onClick={() => handleRemoveStudent(index)}
                            sx={{ backgroundColor: "#C80D0D", color: "#fff", mb: 1, mx: 1, 
                                  "&: hover": {
                                    backgroundColor: "#7B0606"
                                  } 
                            }}
                          >
                            Remove Student
                          </MDButton>
                        )}
                        
                      </Box>
                    ))}
                      
                 
                  
                    <MDButton
                      variant="contained"
                      onClick={handleAddStudent}
                      color="warning"
                      sx={{
                        backgroundColor: "#F7941D", color: "#fff", width: "11vw", mb: 2, fontFamily: "Poppins", "&: hover": {
                          backgroundColor: "#E2E8F0",
                          color: "#081c3d",
                          border: "1px solid #081c3d"
                        }
                      }}
                    >
                      Add Student
                    </MDButton>

                    <MDButton
                      color="warning"
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                      sx={{
                        backgroundColor: "#F7941D", color: "#fff", width: "11vw", fontFamily: "Poppins", "&: hover": {
                          backgroundColor: "#E2E8F0",
                          color: "#081c3d",
                          border: "1px solid #081c3d"
                        }
                      }}
                    >
                      {isLoading ? 'Saving...' : 'Save Students'}
                    </MDButton>
                  </Box>
                </Box>
              </MDBox>
            </>
          ) : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              />
            </div>
          )}

        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StudentForm;
