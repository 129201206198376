
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { Auth } from 'aws-amplify';
import * as Sentry from "@sentry/react";
// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Cover() {

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const [tAndC, setTAndC] = useState(false);

  const handleSetTAndC = (event) => {
    setTAndC(event.target.checked)
  };

  const alertAction = () => setOpenAlert(false);

  const handleUsername = (event) => {
    setUsername(event.currentTarget.value)
  };


  const handleVerificationCode = (event) => {
    setVerificationCode(event.currentTarget.value)
  };

  const handlePassword = (event) => {
    setPassword(event.currentTarget.value)

  };

  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  const verificationHandler = async () => {
    Auth.confirmSignUp(username, verificationCode)
      .then(() => {
        navigate('/profile')
      })
      .catch((e) => {
        Sentry.captureException(e)
      });
  }

  const signUpHandler = async () => {
    // setIsLoaded(false)
    if (!tAndC) {
      openAlertFunction("warning", "warning", "Accept T&C", "Please make sure to selects our Terms and conditions")
    } else {
      try {
        const { user } = await Auth.signUp({
          username,
          password,
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
          }
        });
        setCodeSent(true)
        openAlertFunction("success", "warning", "Your user has been created", "Redirecting you to your profile after verification")
      } catch (error) {
        openAlertFunction("error", "warning", "Sign up issue", "We could not create a user for you at the moment")
      }

      // .then(data => {
      //   console.log(data)
      //   openAlertFunction("success", "warning", "Your user has been created", "Redirecting you to your profile")
      //   setCodeSent(true)
      // })
      // .catch(err => {
      //   console.log(err)
      //   if (err.code = "UsernameExistsException") {
      //     openAlertFunction("error", "warning", "Unable to Signup", "This email is already in use")
      //   } else {
      //     openAlertFunction("error", "warning", "Sign up issue", "We could not create a user for you at the moment")
      //   }
      // });

    }

    // setIsLoaded(true)
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        {!codeSent ? (
          <>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput type="text" label="Email" variant="standard" fullWidth onChange={handleUsername} />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Password" variant="standard" fullWidth onChange={handlePassword} />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox onChange={handleSetTAndC} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </MDTypography>
                  <MDTypography
                    component="a"
                    href="/terms"
                    variant="button"
                    fontWeight="bold"
                    color="warning"
                    textGradient
                  >
                    Terms and Conditions
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="warning" fullWidth onClick={signUpHandler}>
                    sign in
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="warning"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>

            </MDBox>
          </>
        ) : (
          <>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDTypography variant="button" color="text">
                  We have sent you a code to verify your account</MDTypography>
                <MDBox mb={2}>
                  <MDInput type="text" label="Verification Code" value={verificationCode} variant="standard" fullWidth onChange={handleVerificationCode} />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="warning" fullWidth onClick={verificationHandler}>
                    verify
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="warning"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>

            </MDBox>
          </>
        )

        }
        {renderAlert}
      </Card>
    </CoverLayout>
  );
}

export default Cover;
