
import { useState, useContext } from "react";
import "../../../assets/theme/base/colors.js";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { ApiContext } from 'context/meritiumUser';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import * as Sentry from "@sentry/react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { ThreeCircles } from 'react-loader-spinner'
// Authentication layout components
import BasicLayout from "pages/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

/* Import the Amplify Auth API */
import { Auth } from 'aws-amplify';
import {
  useAuthenticator,
} from '@aws-amplify/ui-react';
import { Route, useNavigate } from "react-router-dom";

function Basic() {

  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [IsLoaded, setIsLoaded] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [forcePassword, setForcePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const alertAction = () => setOpenAlert(false);


  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleUsername = (event) => {
    setUsername(event.currentTarget.value)

  };

  const handlePassword = (event) => {
    setPassword(event.currentTarget.value)

  };

  const handleNewPassword  = (event) => {
    setNewPassword(event.currentTarget.value)

  };

  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [userToUse, setUserToUse] = useState();

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );

  const context = useContext(ApiContext);

  const { data, isLoading, error, fetchData } = context;

  const forcePasswordHandler = async () => {
    setIsLoaded(false)
    await Auth.completeNewPassword(userToUse, newPassword)
      .then((user) => {
        navigate("/dashboard")
      })
      .catch((e) => {
        Sentry.captureException(e)
      });
  }

  const loginHandler = async () => {
    setIsLoaded(false)

    await Auth.signIn(username, password)
      .then(async (user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserToUse(user)
          setForcePassword(true)
        } else {
          fetchData()
          navigate('/dashboard')

        }
      })
      .catch((err) => {
        Sentry.captureException(err)
        if (err.code === "UserNotFoundException") {
          openAlertFunction("error", "warning", "No user", "You currently do not have a user on our system.")
        } else if (err.code === "UserNotConfirmedException") {
          Sentry.captureException("Redirect to confirmation")
        } else {
          openAlertFunction("error", "warning", "Login issue", "We could not log you in at this moment")
        }
      });
    setIsLoaded(true)
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="warning"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          sx={{ backgroundColor: "#F7941D" }}
        >
          <MDTypography variant="h4" mt={1} sx={{ color: "#ffffff", fontWeight: 500 }}>
            Sign in
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}> */}
          {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          {/* </Grid> */}
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <>
            {IsLoaded ? (
              <>
                <MDBox component="form" role="form">
                  {forcePassword && (
                    <>
                      <MDTypography variant="button" color="text">
                        You need to set your own password.
                        </MDTypography>
                    </>
                  )}

                  <MDBox mb={2}>
                    <MDInput type="email" label="Email" value={username} fullWidth onChange={handleUsername} />
                  </MDBox>
                  
                  <MDBox mb={2}>
                    <MDInput type="password" label="Password" value={password} fullWidth onChange={handlePassword} />
                  </MDBox>
                  {forcePassword && (
                    <>
                      <MDBox mb={2}>
                        <MDInput type="password" label="New Password"  value={newPassword}  fullWidth onChange={handleNewPassword} />
                      </MDBox>
                    </>
                  )}
                  <MDBox mt={4} mb={1}>
                    {!forcePassword ? (
                      <>
                        <MDButton variant="gradient" fullWidth onClick={loginHandler} sx={{ backgroundColor: "#F7941D", color: "#fff", "&: hover": {
                          backgroundColor: "#E2E8F0", color: "#081c3d"
                        } }}>
                          sign in
                        </MDButton>
                      </>
                    ) : (
                      <>
                        <MDButton variant="gradient" color="warning" fullWidth onClick={forcePasswordHandler}>
                          change
                        </MDButton>
                      </>
                    )}
                  </MDBox>
                  
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" sx={{ fontWeight: 400, color: "081C3D"}}>
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/authentication/sign-up"
                        variant="button"
                        color={"meritumsYellow"}
                        fontWeight="medium"
                        sx={{ "&: hover": {
                          color: "#081c3d"
                        }
                      }}>
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      
                      <MDTypography
                        component={Link}
                        to="/authentication/forgot"
                        variant="button"
                        color={"meritumsYellow"}
                        fontWeight="medium"
                        sx={{ "&: hover": {
                          color: "#081c3d"
                        }
                    }}>
                        Forgot Password
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </>)
              : (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <ThreeCircles
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor="#FFDD00"
                    innerCircleColor="#000000"
                    middleCircleColor="#737373"
                  /></div>

              )}
          </>
          {renderAlert}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
