import React, { useState } from 'react';
import '../pages.css';

import Footer from "layouts/PublicFooter";

import PublicNavbar from "layouts/Navbars/PublicNavbar";
import MDSnackbar from "components/MDSnackbar";
import axios from 'axios';
import { ThreeCircles } from 'react-loader-spinner'
import { position } from 'stylis';
import * as Sentry from "@sentry/react";

export default function Anything() {

    const [isLoaded, setLoading] = useState(true)
    const [contactMethod, setContactMethod] = useState("")
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [dataAdded, setDataAdded] = useState(false)
    const [showAlarms, setShowAlarms] = useState(false)
    const [successfulCreate, setSuccessfulCreate] = useState(false)
    const [notificationID, setNotificationID] = React.useState("")

    const [openAlert, setOpenAlert] = useState(false);

    const alertAction = () => setOpenAlert(false);

    const [alertType, setAlertType] = useState("");
    const [alertIcon, setAlertIcon] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const openAlertFunction = (type, icon, title, message) => {
        setAlertType(type)
        setAlertIcon(icon)
        setAlertTitle(title)
        setAlertMessage(message)
        setOpenAlert(true)
    
      };
    
      const renderAlert = (
        <MDSnackbar
          color={alertType}
          icon={alertIcon}
          title={alertTitle}
          content={alertMessage}
          dateTime=""
          open={openAlert}
          onClose={alertAction}
          close={alertAction}
          bgWhite
        />
      );

    const submitMessage = async () => {
        setLoading(false)
        // const maintanance = await new APIService({}).get("/health")

        const formData = {
            "email_number": contactMethod,
            "information": message,
            "title": title
        }
        await axios.post(
            "http://localhost:8080/api/v2/contact/", formData, {} //Endpoint and parameter or base Url
        ).then(resp => {
            setLoading(true)
            openAlertFunction("success", "warning", "Alert was sent", "We will get back to you as soon as possible")
            // setShowAlarms(true)
            // setSuccessfulCreate(true)
        }).catch(error => {
            Sentry.captureException(resp)
            setLoading(true)
            openAlertFunction("error", "warning", "Login issue", "We could not log you in at this moment")
            
        })

    }

    return (
        <>
            <PublicNavbar />
            <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100" >
                <>
                    <div
                        className="-mt-20 top-20 bottom-auto left-0 right-0 w-full absolute h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-100 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-blueGray-100 border-0">

                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                        {isLoaded ? (
                            <form>
                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    Please provide your information
                                </h6>

                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
                                    And we will get back to you.
                                </h6>
                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-12/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Email or Phone number
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                onChange={(e) => {
                                                    setContactMethod(e.target.value),
                                                        setDataAdded(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-12/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Title
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                onChange={(e) => {
                                                    setTitle(e.target.value),
                                                        setDataAdded(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-6 border-b-1 border-blueGray-300" />
                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                </h6>
                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-12/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Message
                                            </label>
                                            <textarea
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                onChange={(e) => {
                                                    setMessage(e.target.value),
                                                        setDataAdded(true)
                                                }}
                                                rows={4}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                {dataAdded ? (
                                    <a
                                        className="bg-yellow-500 text-white active:bg-yellow-500 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                                        onClick={submitMessage}
                                    >
                                        <i className="fas fa-arrow-alt-circle-down"></i> Submit
                                    </a>
                                ) : (
                                    null
                                )

                                }
                            </form>
                              )
                              : (
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                  <ThreeCircles
                                    height="100"
                                    width="100"
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel="three-circles-rotating"
                                    outerCircleColor="#FFDD00"
                                    innerCircleColor="#000000"
                                    middleCircleColor="#737373"
                                  /></div>
                
                              )}
                          
                        </div>
                    </div>
                </>

            </section>
            {renderAlert}
            <Footer />
        </>
    );
}

