import { useEffect, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { ApiContext } from 'context/meritiumUser';
// Material Dashboard 2 React example components
import SidenavCollapse from "layouts/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "layouts/Sidenav/SidenavRoot";
import sidenavLogoLabel from "layouts/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, routes, ...rest }) {


  const context = useContext(ApiContext);
  const { schoolData, isLoading } = context;

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={"white"}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <>
      {!isLoading && (
        <>
          {
            collapseName === "" || collapseName === "contact" || collapseName === "services" || collapseName === "terms" || collapseName === "privacy" ? (
              <></>
            ) : (
              <SidenavRoot
                {...rest}
                variant="permanent"
                ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
              >
                <MDBox pt={3} pb={1} px={4} textAlign="center">
                  <MDBox
                    display={{ xs: "block", xl: "none" }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                  >
                    <MDTypography variant="h6" color="secondary">
                      <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </MDTypography>
                  </MDBox>

                  <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                    <MDBox
                      width={"100%"}
                      sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                    >
                      <MDBox component="img" src={schoolData?.icon_url || brand} alt="Brand" width="80%" sx={{margin: "auto", borderRadius: "1vh"}} />
                    </MDBox>
                  </MDBox>
                </MDBox>

                <Divider
                  light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                  }
                />

                <MDBox pt={3} pb={1} px={4} textAlign="center" style={{ width: "100%" }}>
                  <MDBox display="flex" alignItems="center" justifyContent="center" width="100%">
                    {/* The content inside will now be centered */}
                    <h5>
                      <span  style={{ color: darkMode ? "#081C3D" : "#ffffff" }}> Welcome </span>
                      <br />
                      <span style={{
                        color: schoolData?.color || "#737373",
                      }}>
                        {schoolData?.school_name}
                      </span>

                      <br />
                    </h5>
                  </MDBox>
                </MDBox>
                
                <Divider
                  light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                  }
                />
                <List color>{renderRoutes}</List>
              </SidenavRoot>
            )
          }
        </>
      )}

    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
