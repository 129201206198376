import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { ApiContext } from 'context/meritiumUser';
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import MDAvatar from "components/MDAvatar";
import Select from "@mui/material/Select";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Auth } from 'aws-amplify';
import { updateFileItem } from "util/api.svs"
import { getItem, requestFileItem } from "util/api.svs"
import { ThreeCircles } from 'react-loader-spinner'
import MDSnackbar from "components/MDSnackbar";
import * as Sentry from "@sentry/react";
import { HexColorPicker } from "react-colorful";
import { Button } from "@mui/material";
import { updateItem } from "util/api.svs";

function Settings(props) {
    const [IsLoaded, setIsLoaded] = useState(false);
    const context = useContext(ApiContext);
    const { fetchData, isLoading, schoolData } = context;
    const [color, setColor] = useState("#ffdd00");
    const [points, setPoints] = useState([]);
    const [formData, setFormData] = useState({});
    const [value, setValue] = useState(0);

    useEffect(() => {
        async function fetchEffectData() {
            setIsLoaded(false)
            await Auth.currentAuthenticatedUser();
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            getItem(`/admin/schools/${schoolData.uuid}`, token).then(resp => {
                console.log(resp.data)
                setFormData(resp.data)
                setPoints(resp.data.school_totals || []);
                setIsLoaded(true)
            }).catch(error => {
                Sentry.captureException(error)
                setIsLoaded(true)
            })
            if (schoolData && schoolData.color) {
                setColor(schoolData.color);
            }

          

        }
        if (!isLoading) {
            fetchEffectData();
        }
    }, [isLoading])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };



    const [profilePicture, setProfilePicture] = useState(schoolData?.company_logo || '');
    const [isPictureUploaded, setIsPictureUploaded] = useState(false);

    const updateProfilePicture = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setIsPictureUploaded(true);
                setProfilePicture(e.target.result);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    company_logo: e.target.result,
                }));
            };

            const blob = new Blob([file], { type: file.type });
            reader.readAsDataURL(blob);
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            updateFileItem(`/setting/${schoolData?.business_id}/logo`, file, token).then((resp) => {
                openAlertFunction("success", "success", "Updated", "We have updated your image")
                fetchData()
            }).catch((error) => {
                Sentry.captureException(error)
                openAlertFunction("warning", "warning", "Issue changing logo", "We could not change your settings.")
            })
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };



    // Points
    const handlePointInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedPoints = [...points];
        updatedPoints[index][name] = name === "min_point" ? parseInt(value) : value; // Parse value as an integer if it's "min_point"
        setPoints(updatedPoints);
    };

    const addPoint = () => {
        setPoints([...points, { name: "", min_point: "", color: "" }]);
    };

    // Function to remove a point
    const removePoint = (index) => {
        const updatedPoints = [...points];
        updatedPoints.splice(index, 1);
        setPoints(updatedPoints);
    };

    // Alerting

    const [alertType, setAlertType] = useState("success");
    const [alertIcon, setAlertIcon] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const alertAction = () => setOpenAlert(false);

    const openAlertFunction = (type, icon, title, message) => {
        setAlertType(type)
        setAlertIcon(icon)
        setAlertTitle(title)
        setAlertMessage(message)
        setOpenAlert(true)
    };

    const renderAlert = (
        <MDSnackbar
            color={alertType}
            icon={alertIcon}
            title={alertTitle}
            content={alertMessage}
            dateTime=""
            open={openAlert}
            onClose={alertAction}
            close={alertAction}
            bgWhite
        />
    );

    const handleSubmit = async (e) => {
        setIsLoaded(false)
        e.preventDefault();
        const updatedSchoolData = { ...formData, school_totals: points };
        console.log("Updating to ", updatedSchoolData)
        // You can now use `updatedSchoolData` for further processing or API calls
        // For example:
        // await updateSchoolData(updatedSchoolData);
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
        updateItem(`/admin/schools`, updatedSchoolData, token).then(resp => {
            console.log("Update Resp", resp)
            setIsLoaded(true)
          }).catch(resp => {
            console.log(resp)
            setIsLoaded(true)
          })
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox paddingTop={5}>
                <Card>
                    {IsLoaded ? (
                        <>
                            <MDBox padding={4}>
                                <Tabs value={value} onChange={handleTabChange} centered>
                                    <Tab label="General" />
                                    <Tab label="Points" />
                                </Tabs>
                                {value === 0 && (
                                    <>
                                        <MDTypography variant="h6" mt={2} mb={3} sx={{ color: "#F7941D", fontSize: "1.2rem", fontFamily: "Poppins", fontWeight: 500 }}>
                                            Settings
                                        </MDTypography>
                                        <br />
                                        <Grid container spacing={3} justifyContent="center">
                                            <form>
                                                <Grid container spacing={2}>
                                                    <form>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <MDAvatar
                                                                    src={
                                                                        profilePicture !== ""
                                                                            ? isPictureUploaded
                                                                                ? profilePicture
                                                                                : profilePicture
                                                                                    ? `${process.env.REACT_APP_IMAGE_BASE}/${profilePicture}`
                                                                                    : "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
                                                                            : "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
                                                                    }
                                                                    alt="profile-image"
                                                                    size="xxl"
                                                                    shadow="sm"
                                                                    sx={{
                                                                        marginBottom: "1em",
                                                                        position: "relative",
                                                                        "&:hover .edit-icon": {
                                                                            display: "block",
                                                                        },
                                                                    }}
                                                                />
                                                                <IconButton sx={{ position: "absolute", top: "6.5em", left: "52%" }}>
                                                                    <label htmlFor="profilePictureInput">
                                                                        <EditIcon sx={{ color: "#F7941D", mx: 1 }} />
                                                                    </label>
                                                                    <input
                                                                        id="profilePictureInput"
                                                                        type="file"
                                                                        accept="image/*"
                                                                        style={{ display: "none" }}
                                                                        onChange={updateProfilePicture}
                                                                    />
                                                                </IconButton>
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <MDInput
                                                                    name="school_name"
                                                                    label={<label style={{ color: '#F7941D', fontWeight: 'semibold' }}>Name</label>}
                                                                    value={formData.school_name}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <MDInput
                                                                    name="contact_email"
                                                                    label={<label style={{ color: '#F7941D', fontWeight: 'semibold' }}>Contact Email</label>}
                                                                    value={formData.contact_email}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <MDInput
                                                                    name="contact_phone_number"
                                                                    label={<label style={{ color: '#F7941D', fontWeight: 'semibold' }}>Contact Number</label>}
                                                                    value={formData.contact_phone_number}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <MDInput
                                                                    name="point_name"
                                                                    label={<label style={{ color: '#F7941D', fontWeight: 'semibold' }}>Point names</label>}
                                                                    value={formData.point_name}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Divider />
                                                        <MDTypography variant="h6" mb={3} sx={{ color: "#081c3d", fontSize: "1.2rem", fontFamily: "Poppins", fontWeight: 400 }}>
                                                            Color
                                                        </MDTypography>

                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Grid item xs={12} md={6}>
                                                                    <HexColorPicker color={color} onChange={setColor} />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <MDInput
                                                                        name="color_code"
                                                                        label={<label style={{ color: '#F7941D', fontWeight: 'semibold' }}>Color Code</label>}
                                                                        value={color}
                                                                        onChange={handleInputChange}
                                                                        required
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>


                                                        <Divider />
                                                        <Grid item xs={12} md={12}>
                                                            <MDButton
                                                                onClick={handleSubmit}
                                                                sx={{
                                                                    backgroundColor: "#F7941D", color: "#fff", fontFamily: "Poppins",
                                                                    "&: hover": {
                                                                        backgroundColor: "#E2E8F0",
                                                                        color: "#081c3d",
                                                                        border: "1px solid #081c3d"
                                                                    }
                                                                }}
                                                            >
                                                                Save
                                                            </MDButton>
                                                        </Grid>


                                                    </form>
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </>
                                )}
                                {value === 1 && (
                                    <>
                                        <MDTypography variant="h6" mt={3} mb={3} sx={{ color: "#F7941D", fontSize: "1.2rem", fontFamily: "Poppins", fontWeight: 500 }}>
                                            Points Settings
                                        </MDTypography>

                                        <br />
                                        <Grid container>
                                            <form onSubmit={handleSubmit}>
                                                <Grid container>
                                                    {points.map((point, index) => (
                                                        <Grid container spacing={2}>
                                                            
                                                            <Grid item xs={12} md={4} my={1.5}>

                                                                <MDInput
                                                                    name="name"
                                                                    label="Name"
                                                                    value={point.name}
                                                                    onChange={(e) => handlePointInputChange(e, index)}
                                                                    required
                                                                    fullWidth
                                                                    sx={{
                                                                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                                                                          borderColor: "#F7941D"
                                                                        },
                                                                        "& .MuiInputBase.Mui-focused": {
                                                                          border: "2px solid #F7941D !important",
                                                                          color: "#F7941D"
                                                                        },
                                                                        "& label.Mui-focused": {
                                                                            color: "#081c3d",
                                                                            color: "#F7941D"
                                                                        },
                                                                          "& .MuiOutlinedInput-root.Mui-focused": {
                                                                            "& > fieldset": {
                                                                              borderColor: "#F7941D !important",
                                                                              color: "#F7941D"
                                                                            }
                                                                          }
                                                                        }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={4} my={1.5}>
                                                                <MDInput
                                                                    name="min_point"
                                                                    label="Min Points"
                                                                    type="number" // Set the type to "number"
                                                                    value={point.min_point}
                                                                    onChange={(e) => handlePointInputChange(e, index)}
                                                                    required
                                                                    fullWidth
                                                                    sx={{
                                                                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                                                                          borderColor: "#F7941D"
                                                                        },
                                                                        "& .MuiInputBase.Mui-focused": {
                                                                          border: "2px solid #F7941D !important",
                                                                          color: "#F7941D"
                                                                        },
                                                                        "& label.Mui-focused": {
                                                                            color: "#081c3d",
                                                                            color: "#F7941D"
                                                                        },
                                                                          "& .MuiOutlinedInput-root.Mui-focused": {
                                                                            "& > fieldset": {
                                                                              borderColor: "#F7941D !important",
                                                                              color: "#F7941D"
                                                                            }
                                                                          }
                                                                        }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={4} my={1.5}>
                                                                <MDInput
                                                                    name="color"
                                                                    label="Color"
                                                                    value={point.color}
                                                                    onChange={(e) => handlePointInputChange(e, index)}
                                                                    fullWidth
                                                                    sx={{
                                                                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                                                                          borderColor: "#F7941D"
                                                                        },
                                                                        "& .MuiInputBase.Mui-focused": {
                                                                          border: "2px solid #F7941D !important",
                                                                          color: "#F7941D"
                                                                        },
                                                                        "& label.Mui-focused": {
                                                                            color: "#081c3d",
                                                                            color: "#F7941D"
                                                                        },
                                                                          "& .MuiOutlinedInput-root.Mui-focused": {
                                                                            "& > fieldset": {
                                                                              borderColor: "#F7941D !important",
                                                                              color: "#F7941D"
                                                                            }
                                                                          }
                                                                        }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} my={2}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => removePoint(index)}
                                                                    sx={{ backgroundColor: "#C80D0D", color: "#fff", width: "11vw", my: 2, 
                                                                            "&: hover": {
                                                                                backgroundColor: "#7B0606"
                                                                            } 
                                                                    }}
                                                                >
                                                                    Remove option
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    ))}

                                                    <Grid item xs={12}>
                                                        <MDButton onClick={addPoint} sx={{ backgroundColor: "#005C82", color: "#ffffff", my: "1vh", "&: hover, &:active, &: focus": {
                                                            backgroundColor: "#4BC5D6",
                                                            color: "#081c3d"
                                                        }}}>Add Point</MDButton>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} md={12}>
                                                        <MDButton
                                                            onClick={handleSubmit}
                                                            sx={{
                                                                backgroundColor: "#F7941D", color: "#fff", fontFamily: "Poppins",
                                                                "&:hover": {
                                                                    backgroundColor: "#E2E8F0",
                                                                    color: "#081c3d",
                                                                    border: "1px solid #081c3d"
                                                                }
                                                            }}
                                                        >
                                                            Save
                                                        </MDButton>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </>
                                )}
                            </MDBox>
                        </>
                    ) : (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh'
                        }}>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor="#FFDD00"
                                innerCircleColor="#000000"
                                middleCircleColor="#737373"
                            />
                        </div>
                    )}
                </Card>
            </MDBox>
            {renderAlert}
            <Footer />
        </DashboardLayout>
    );
}

export default Settings;
