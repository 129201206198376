import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';

const ProtectedRoute = (props) => {

    const context = useContext(ApiContext);
    const { userSelectedTransporter, userSelectedBusiness, isLoading } = context;

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isValidPath, setIsValidPath] = useState(false);


    const checkUserToken = async () => {
        await Auth.currentSession().then(data => {
            if (!isLoading) {
                setIsLoggedIn(true);
                if (userSelectedBusiness !== undefined) {
                    if (!props.route.linked) {
                        setIsValidPath(true)
                    } else {
                        if (props.route.linked) {
                            const viewTypeIndex = userSelectedBusiness.is_type.includes(props.route.view_type);
                            if (viewTypeIndex !== -1) {
                                setIsValidPath(true);
                            } else {
                                setIsValidPath(false);
                                return navigate('/dashboard');
                            }

                        } else {
                            setIsValidPath(true)
                        }

                    }

                } else {
                    setIsValidPath(true)
                }
            }


        }).catch(err => {
            console.log("Error in PR:", err)
            setIsLoggedIn(false);
            return navigate('/authentication/sign-in');
        });

    }

    useEffect(() => {
        if (!isLoading) {
            checkUserToken();
        }
    }, [isLoggedIn, isValidPath, props, isLoading]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? (
                    <>
                        {isValidPath ? props.children : null}
                    </>
                ) : (
                    null
                )
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;