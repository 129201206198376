import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState, useContext } from "react";
import { ThreeCircles } from 'react-loader-spinner'
import { Auth } from 'aws-amplify';
import { ApiContext } from 'context/meritiumUser';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as Sentry from "@sentry/react";
import "../../assets/styles/tailwind.css";
import { styled } from '@mui/system';
import { Delete as DeleteIcon, Add as AddIcon, ArrowUpward as ArrowUpwardIcon, Visibility as VisibilityIcon } from '@mui/icons-material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import MDSnackbar from "components/MDSnackbar";

// Data
import { getItem } from "util/api.svs"
import { postItem, deleteItem } from "util/api.svs";

import {
  useMaterialUIController,
} from "context";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    backgroundColor: '#009688',
  },
});

function Students() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const context = useContext(ApiContext);
  const { isLoading, userSelectedBusiness, userData, schoolData } = context;

  const [IsLoaded, setIsLoaded] = useState(false);
  const [rows, setRow] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);

  // Alerts

  const [alertType, setAlertType] = useState("success");
  const [alertIcon, setAlertIcon] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const alertAction = () => setOpenAlert(false);

  const openAlertFunction = (type, icon, title, message) => {
    setAlertType(type)
    setAlertIcon(icon)
    setAlertTitle(title)
    setAlertMessage(message)
    setOpenAlert(true)

  };

  const renderAlert = (
    <MDSnackbar
      color={alertType}
      icon={alertIcon}
      title={alertTitle}
      content={alertMessage}
      dateTime=""
      open={openAlert}
      onClose={alertAction}
      close={alertAction}
      bgWhite
    />
  );


  const handleRemove = async (email) => {
    setIsLoaded(false)
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    deleteItem(`/user/${userSelectedBusiness.business_id}/${email}`, token).then(resp => {
      setRow((prevRows) => {
        // Filter the rows to remove the contract with the specified object_id
        const updatedData = prevRows.filter((row) => row.email !== email);
        // Return the updated rows to be used as the new state
        return updatedData;
      });
      setIsLoaded(true)
    }).catch(error => {
      Sentry.captureException(error)
      setIsLoaded(true)
    })
  };

  const manageClick = (email, object) => {
    object.email = email
    setUpdateFormData(object)
    // setOpen(true);
    handleOpenUpdateModal()
  };


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputUpdateChange = (e) => {
    setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add row data to the rows array state
    console.log("Checking:", formData.email)
    console.log("Checking:", formData)
    if (formData.email === '') {
      console.log("here")
      // Handle validation errors (you can replace this with your error handling logic)
      // notify("tl", "Please make sure you selected all fields.", "warning");
      openAlertFunction(
        "warning",
        "warning",
        "Required information",
        "Please make sure you selected all fields."
      );

    } else {
      console.log("wtf")
      // Validation passed, add data to the inviteRows state
      setInviteRows([...inviteRows, formData]);

      // Reset the form fields
      setFormData({ email: '', role: 'General' });
    }
  };

  const cancelInvite = (e) => {
    setInviteRows([])
    handleCloseModal()
  }

  const cancelManage = (e) => {
    handleCloseUpdateModal()
  }

  const handleRemoveRow = (index) => {
    const updatedRows = [...inviteRows];
    updatedRows.splice(index, 1);
    setInviteRows(updatedRows);

  };

  const handleSendingInvites = async (e) => {
    setIsLoaded(false)
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const formattedInviteRows = inviteRows.reduce((acc, row) => {
      acc[row.email] = { group: row.group };
      return acc;
    }, {});
    postItem(`/user/${userSelectedBusiness.business_id}/invite`, formattedInviteRows, token).then((resp) => {
      setInviteRows([])
      handleCloseModal()
      setIsLoaded(true)
      openAlertFunction(
        "success",
        "success",
        "Invites sending...",
        "Once the invites has been sent you can come back and the team will be visible."
      );
    }
    ).catch((err) => {
      Sentry.captureException(err)
      setIsLoaded(true)
      openAlertFunction(
        "errorr",
        "errorr",
        "Invites could not be sent.",
        "Our team has been notified. You can try again or reach out to us if it persists."
      );
    })
  };


  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState({});
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  const navigate = useNavigate();

  const assignMerit = (student) => {
    navigate(`/merits-asign/${student.id}`);
  };

  const viewStudent = (student) => {
    navigate(`/student/${student.id}`);
  };

  const removeStudent = async (student) => {
    setIsLoaded(false)
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    console.log("Removing", student)
    deleteItem(`/admin/students/${student.id}`, token).then(resp => {
      console.log()
      setRow((prevRows) => {
        // Filter the rows to remove the contract with the specified object_id
        const updatedData = prevRows.filter((row) => row.uuid !== student.id);
        // Return the updated rows to be used as the new state
        return updatedData;
      });
      openAlertFunction("success", "success", "Student Deleted", "We have removed this student")
      setIsLoaded(true)
    }).catch(error => {
      Sentry.captureException(error)
      setIsLoaded(true)
    })
  };



  const sortRowsAlphabetically = (rows, column) => {
    return rows.slice().sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const columns = [
        { field: 'first_name', headerName: 'Name', flex: 1 },
        { field: 'last_name', headerName: 'Surname', flex: 1 },
        { field: 'point_total', headerName: schoolData?.point_name || "Points", flex: 1 },
        { field: 'grade', headerName: 'Grade', flex: 1 },
        {
          field: 'badge_awarded',
          headerName: 'New Badge',
          flex: 1,
          renderCell: (params) => (
            <>
              {!params.row.badge_awarded && (
                <>
                  Needs new badge.
                </>
              )}
            </>
          ),
        },
      ];

      console.log(userData);

      if (userData.is_admin === 'true') {
        columns.push({
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          renderCell: (params) => (
            <div>
              {userData.is_admin && ( // Check if user is admin
                <AddIcon
                  onClick={(e) => { assignMerit(params) }}
                  sx={{ mb: 2, cursor: 'pointer', fontSize: '1.3rem !important', m: 0.5, color: "green" }}
                />
              )}

              <VisibilityIcon
                onClick={(e) => { viewStudent(params) }}
                sx={{ mb: 2, cursor: 'pointer', fontSize: '1.1rem !important', m: 0.5, color: "grey" }}
              />

              <ArrowUpwardIcon
                // onClick={} // Add your promote logic here
                sx={{ mb: 2, cursor: 'pointer', fontSize: '1.1rem !important', m: 0.5, color: "black" }}
              />

              <DeleteIcon
                onClick={(e) => { removeStudent(params) }}
                sx={{ mb: 2, cursor: 'pointer', fontSize: '1.1rem !important', m: 0.5, color: "red" }}
              />

              {/* Render the button only if user is admin */}
            </div>
          ),
        });
      }

      setTableColumns(columns); // Moved inside fetchData function
    }

    async function fetchDataFromApi() {
      try {
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const resp = await getItem(`/students`, token);
        console.log(resp.data);
        const sortedRows = sortRowsAlphabetically(resp.data, 'first_name');
        setRow(sortedRows);
        setIsLoaded(true);
      } catch (error) {
        Sentry.captureException(error);
        setIsLoaded(true);
      }
    }

    if (!isLoading) {
      fetchData();
      fetchDataFromApi(); // Invoke fetchDataFromApi here
    }
  }, [isLoading]);


  const handleGradeFilter = (event) => {
    const selectedGrade = event.target.value;
    setSelectedGrade(selectedGrade);

    // Filter rows based on selected grade
    if (selectedGrade === "") {
      setFilteredRows(rows); // Reset to all rows if no grade selected
    } else {
      const filtered = rows.filter(row => row.grade === selectedGrade);
      setFilteredRows(filtered);
    }
  };

  const renderDataGrid = () => {
    const data = selectedGrade !== null ? filteredRows : rows;

    return (
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            color: '#081c3d',
            fontWeight: 700,
          },
        }}
        rows={data}
        columns={tableColumns}
        getRowId={(row) => row.uuid}
        loading={!IsLoaded}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight
        checkboxSelection={false} // Change as needed
        disableRowSelectionOnClick={true} // Change as needed
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />


      <MDBox pt={6} pb={3}>
        {IsLoaded ? (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
                {/* Dropdown for grade filtering */}
                <Select
                  value={selectedGrade || ''}
                  onChange={handleGradeFilter}
                  fullWidth
                  displayEmpty
                  sx={{
                    fontSize: "1rem",
                    padding: 1,
                    border: "2px solid #73A5AC !important", fontFamily: "Poppins",
                    "&:focus": {
                      borderColor: "#F7941D !important"
                    },
                    "&:active": {
                      borderColor: "#F7941D !important"
                    }
                  }}
                >
                  <MenuItem value={""}>All Grades</MenuItem>
                  <MenuItem value={"R"}>Grade R</MenuItem>
                  <MenuItem value={"1"}>Grade 1</MenuItem>
                  <MenuItem value={"2"}>Grade 2</MenuItem>
                  <MenuItem value={"3"}>Grade 3</MenuItem>
                  <MenuItem value={"4"}>Grade 4</MenuItem>
                  <MenuItem value={"5"}>Grade 5</MenuItem>
                  <MenuItem value={"6"}>Grade 6</MenuItem>
                  <MenuItem value={"7"}>Grade 7</MenuItem>

                  {/* Add more grade options */}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ backgroundColor: "#B2BFD0" }}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{ backgroundColor: "#F7941D" }}
                  >
                    <MDTypography variant="h6" sx={{ color: "#fff", fontWeight: 600, fontFamily: "Poppins", fontSize: "1.1rem" }}>
                      Students
                    </MDTypography>
                  </MDBox>

                  <MDBox pt={3} sx={{ backgroundColor: "#B2BFD0" }}>
                    {renderDataGrid()}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>

          </>)
          : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#FFDD00"
                innerCircleColor="#000000"
                middleCircleColor="#737373"
              /></div>

          )
        }
      </MDBox>

      {renderAlert}
      <Footer />
    </DashboardLayout>
  );
}

export default Students;
