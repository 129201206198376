
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// components
import logo from 'assets/images/gistics-full.png'
import { Auth } from 'aws-amplify';

// import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function PublicNavbar() {
  const [divbarOpen, setNavbarOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);

  useEffect(() => {
    const checlLoggedIn = async () => {
      await Auth.currentSession().then(data => {
        console.log("Logged in")
        setLoggedIn(true)
      }).catch(() =>{
        setLoggedIn(false)
      });
    }
    checlLoggedIn()
  }, [])

  return (
    <div className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 divbar-expand-lg landing shadow-lg">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            to="/#"
            className="text-white text-2xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase heading"
          >
            Meritums
          </Link>

          <button
            className="cursor-pointer text-xl bg-blue-500 leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!divbarOpen)}
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>

        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
            (divbarOpen ? " block" : " hidden")
          }
          id="example-divbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            {/* <li className="flex items-center">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/services"
              >
                <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />
                Services
              </a>
            </li> */}
            {/* <li className="flex items-center">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/pricing"
              >
                <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />
                Prices
              </a>
            </li> */}
           
          </ul>

          {loggedIn ? (<>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                  className="bg-meritums-yellow-500 text-white text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150 getStarted"
                  href="/application"
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Go to console
                </a>
              </li>
            </ul>
            
          </>) : 
          (<>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-meritums-cyan text-white px-3 py-1 lg:py-2 flex items-center text-sm uppercase font-semibold"
                  href="/contact"
                  style={{ fontFamily: "Poppins"}}
                >
                  <i className="text-white far fa-file-alt text-lg leading-lg mr-2" />
                  Contact Us
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="text-white active:bg-yellow-500 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150 getStarted"
                  href="/application"
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Login/Signup
                </a>
              </li>
            </ul>
          </>)
          }
        </div>
      </div>
    </div>
  );
}
