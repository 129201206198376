
import { useState, useContext } from "react";
import "../../assets/styles/tailwind.css";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { Radio, FormControlLabel } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { TextField } from '@mui/material';
import { ApiContext } from 'context/meritiumUser';
import { postItem } from "util/api.svs"
import { Auth } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Support() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState('user_linked');

  const context = useContext(ApiContext);
  const { isLoading, userSelectedBusiness } = context;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dataToSend = {
      "type": selectedValue,
      "message": message,
    }

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (selectedValue === "user_linked") {
      const user = await Auth.currentAuthenticatedUser();
      dataToSend.linked_id = user.attributes.sub
    } else {
      dataToSend.linked_id = userSelectedBusiness.business_id
    }
    postItem(`/support/`, dataToSend, token).then((resp) => {
      console.log(resp)
    }).catch((error) => {
      Sentry.captureException(error)

    })

  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox padding={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Card>
          <MDBox mt={6} mb={3} style={{ minHeight: "70vh" }}>
            <Grid container spacing={3} justifyContent="center">


              <form >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDTypography variant="h4" mt={1} sx={{ fontFamily: "Poppins", color: "#494D68", fontSize: "1.25rem", fontWeight: 400 }}>

                      Send us a message to support you.
                    </MDTypography>
                  </Grid>
                  {
                    userSelectedBusiness !== null && (
                      <>
                        <Grid item xs={6}>

                          <FormControlLabel
                            value="user_linked"
                            control={<Radio color="warning" checked={selectedValue === 'user_linked'} />}
                            label="Your own ticket"
                            checked={selectedValue === 'user_linked'}
                            onClick={handleChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControlLabel
                            value="business_linked"
                            control={<Radio color="warning" checked={selectedValue === 'business_linked'} />}
                            label="My business's ticket"
                            checked={selectedValue === 'business_linked'}
                            onClick={handleChange}
                          />
                        </Grid>
                      </>
                    )
                  }


                  <Grid item xs={8} mx={"auto"}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      sx={{
                        "& .css-ob3on5-MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input": {
                          borderColor: "#F7941D"
                        },
                        "& .MuiInputBase.Mui-focused": {
                          border: "2px solid #F7941D !important",
                          color: "#F7941D"
                        },
                        "& label.Mui-focused": {
                            color: "#081c3d",
                            color: "#F7941D"
                        },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#F7941D !important",
                              color: "#F7941D"
                            }
                          }
                        }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDButton variant="gradient" onClick={handleSubmit}
                      sx={{
                        backgroundColor: "#F7941D", border: "1px solid transparent",
                        fontFamily: "Poppins", color: "#fff", fontWeight: 700,
                        fontSize: "0.875rem",
                        "&:hover": {
                          backgroundColor: "#E2E8F0",
                          color: "#081c3d",
                          border: "1px solid #081c3d",
                        }
                      }}>Send Ticket</MDButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </MDBox >
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Support;
