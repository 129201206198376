
// Material Dashboard 2 React layouts
import Dashboard from "pages/dashboard";
import Students from "pages/students";
import Support from "pages/support";
import Profile from "pages/profile";
import Settings from "pages/settings";
import MeritsApproval from "pages/merit-approvals"
import MeritsTemplates from "pages/merit-templates"
import StudentManagement from "pages/students-management"

import Merits from "pages/merits"

// Auth
import SignIn from "pages/authentication/sign-in";
import SignUp from "pages/authentication/sign-up";
import Change from "pages/authentication/reset-password/change";
import Forgot from "pages/authentication/reset-password/forgot";
import Required from "pages/authentication/reset-password/required";
import Reset from "pages/authentication/reset-password/required";
// private
import Landing from "pages/landing";
import Contact from "pages/contact";
import Services from "pages/services";
import Privacy from "pages/privacy";
import Terms from "pages/terms";

// @mui icons
import Icon from "@mui/material/Icon";
import MeritsTemplatesAdd from "pages/merit-templates/add-new";
import AssignMerrit from "pages/merits/assign";
import Student from "pages/students/student";
import Merit from "pages/merits/merit";

// Routes are for react router to be created and not meant for sidenavs
const routes = [
  {
    type: "hidden",
    name: "Landing",
    key: "landing",
    icon: <Icon fontSize="small">landing</Icon>,
    route: "/",
    component: <Landing />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">contact</Icon>,
    route: "/contact",
    component: <Contact />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">services</Icon>,
    route: "/services",
    component: <Services />,
    private: false,
    linked: false,
    type: "public"
  },

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    private: true,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/students",
    component: <Students />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merits",
    key: "merits",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merits",
    component: <Merits />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  // Pages
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    private: true,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/students",
    component: <Students />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Student",
    key: "student",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/student/:student_id",
    component: <Student />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merits",
    key: "students",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merits",
    component: <Merits />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merits",
    key: "merit-assign",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merits-asign/:id",
    component: <AssignMerrit />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merit",
    key: "merit",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merit/:id",
    component: <Merit />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merits",
    key: "merit-assign",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merits-asign",
    component: <AssignMerrit />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  {
    type: "collapse",
    name: "Approvals",
    key: "approvals",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/approvals",
    component: <MeritsApproval />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merit Templates",
    key: "merit-templates",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merit-templates",
    component: <MeritsTemplates />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Add Merit Templates",
    key: "merit-templates-add",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/merit-templates-add",
    component: <MeritsTemplatesAdd />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Student Creation",
    key: "student-management",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/student-management",
    component: <StudentManagement />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/settings",
    component: <Settings />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/support",
    component: <Support />,
    private: true,
    linked: false,
    view_type: "any"
  },
  // Pages end
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/support",
    component: <Support />,
    private: true,
    linked: false,
    view_type: "any"
  },
  {
    type: "hidden",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    private: true,
    linked: false,
    view_type: "any"
  },
  // Auth
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Password Reset",
    key: "password-reset",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset",
    component: <Reset/>,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Password Reset",
    key: "password-reset",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/change",
    component: <Change />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Password Reset",
    key: "password-reset",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/forgot",
    component: <Forgot />,
    private: false,
    linked: false,
    type: "public"
  },
  {
    type: "hidden",
    name: "Password Reset Required",
    key: "password-reset-required",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/required",
    component: <Required />,
    private: false,
    linked: false,
    type: "public"
  },
];

// Sidenav routes
const noBusinessRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard_nobus",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>dashboard</Icon>,
    route: "/dashboard",
  },
  {
    type: "collapse",
    name: "Support",
    key: "support_nobus",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>receipt_long</Icon>,
    route: "/support",
  },
]

const portalAllRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    private: true,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>group_icon</Icon>,
    route: "/students",
    component: <Students />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merits",
    key: "merits",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>military_tech_icon</Icon>,
    route: "/merits",
    component: <Merits />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  {
    type: "collapse",
    name: "Approvals",
    key: "approvals",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>approval</Icon>,
    route: "/approvals",
    component: <MeritsApproval />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Merit Templates",
    key: "merit-templates",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>table_view</Icon>,
    route: "/merit-templates",
    component: <MeritsTemplates />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Student Management",
    key: "student-management",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>manage_accounts</Icon>,
    route: "/student-management",
    component: <StudentManagement />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>settings</Icon>,
    route: "/settings",
    component: <Settings />,
    private: false,
    linked: false,
    view_type: "any"
  },
  {
    type: "divider",
    name: "extra",
    key: "extra",
    private: true,
    linked: false
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small" style={{ color: "#6E7182" }}>contact_support_icon</Icon>,
    route: "/support",
    component: <Support />,
    private: true,
    linked: false,
    view_type: "any"
  },
];



export default routes;
export const noBusiness = noBusinessRoutes;
export const portalRoutes = portalAllRoutes;

